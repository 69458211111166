import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Auth, API } from "aws-amplify";
import Helmet from "react-helmet";
import validator from "validator";
import DOMPurify from "dompurify";
import NumberFormat from "react-number-format";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";

import BlueFans from "../images/fans-blue.jpg";

const styles = (theme) => ({
  header: {
    padding: "2rem 1rem",
    backgroundImage: `url(${BlueFans})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "relative",
    marginBottom: "2.5rem",
  },
  pageTitle: {
    marginTop: "2.25rem",
    textTransform: "uppercase",
  },
  [theme.breakpoints.up("md")]: {
    header: {
      padding: "3rem",
      position: "relative",
    },
  },
  [theme.breakpoints.up("md")]: {
    pageTitle: {
      marginTop: "0rem",
    },
  },
  logo: {
    marginBottom: "3rem",
    height: "50px",
  },
  MuiButton: {
    borderRadius: "4px",
    padding: ".75rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    color: "white",
  },
  redButton: {
    color: "#E03A3E",
  },
  blueButton: {
    color: "#2196f3",
  },
  MuiListItem: {
    borderBottom: "none",
    paddingLeft: 0,
    paddingRight: 0,
  },
  form: {
    marginTop: "1.5rem",
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  Snackbar: {
    zIndex: "999999999999999 !important",
  },
  login: {
    paddingRight: "2rem",
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    height: 75,
    width: 75,
  },
});

function MobileNoCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="(###) ### ####"
      thousandSeparator=""
      prefix=""
      mask="_"
    />
  );
}

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      email: "",
      mobileNo: "",
      password: "",
      confirmPassword: "",
      isValidName: true,
      isValidEmail: true,
      isValidMobileNo: true,
      isValidPassword: true,
      isValidConfirmPassword: true,
      isSubmitting: false,
      isSubmitted: false,
    };
  }

  componentWillMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/listings");
    }

    localStorage.removeItem("sellListingData");
  }

  selectTeam(slug) {
    this.setState({
      selectedTeam: slug,
    });
  }

  sanitizeInput = (input) => {
    return DOMPurify.sanitize(input, {
      USE_PROFILES: { html: false, svg: false, mathMl: false }
    });
  };

  isValidName = (name) => {
    const isValidName = name !== "" && /^[a-zA-Z\s]+$/.test(name);
    this.setState({ isValidName });
    return isValidName;
  };

  isValidEmail = (email) => {
    const isValidEmail = validator.isEmail(email);
    this.setState({ isValidEmail });
    return isValidEmail;
  };

  isValidMobileNo = async (mobileNo) => {
    const sanitizedMobileNo = this.sanitizeInput(mobileNo);
    let isVerifiedMobileNo = await API.post("v2", `marketplace/accounts/verify-seller-phone`, {
      body: {
        phone_number: `+1${sanitizedMobileNo}`,
      },
    });
    let isValidMobileNo = validator.isMobilePhone(sanitizedMobileNo, ["en-CA"]) && isVerifiedMobileNo;
    this.setState({ isValidMobileNo });
    return isValidMobileNo;
  };

  isValidPassword(password) {
    const isValidPassword = password !== "" && password.length >= 6;
    this.setState({ isValidPassword });
    return isValidPassword;
  }

  isValidConfirmPassword(password, confirmPassword) {
    const isValidConfirmPassword =
      confirmPassword !== "" && password === confirmPassword;
    this.setState({ isValidConfirmPassword });
    return isValidConfirmPassword;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  async isValidSignup(fullName, email, mobileNo, password, confirmPassword) {
    const isValidMobileNo = await this.isValidMobileNo(mobileNo);
    const isValidName = this.isValidName(fullName);
    const isValidEmail = this.isValidEmail(email);
    const isValidPassword = this.isValidPassword(password);
    const isValidConfirmPassword = this.isValidConfirmPassword(
      password,
      confirmPassword
    );

    return (
      isValidName &&
      isValidEmail &&
      isValidMobileNo &&
      isValidPassword &&
      isValidConfirmPassword
    );
  }

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    const { fullName, email, mobileNo, password, confirmPassword } = this.state;

    if (
      await this.isValidSignup(fullName, email, mobileNo, password, confirmPassword)
    ) {
      const sellerMobileNo = `+1${mobileNo}`;
      const sanitizedFullName = this.sanitizeInput(fullName);
      const data = {
        email,
        fullName: sanitizedFullName,
        mobileNo: sellerMobileNo,
      };

      localStorage.setItem("sellListingData", JSON.stringify(data));

      try {
        await Auth.signUp({
          username: email.toLowerCase().trim(),
          password: password,
          attributes: {
            email: email.toLowerCase().trim(),
            phone_number: sellerMobileNo,
            name: sanitizedFullName,
          },
        });

        await Auth.signIn(email.toLowerCase().trim(), password);

        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

        this.props.setUserAttributes(user);
        this.props.userHasAuthenticated(true);

        this.props.history.push(`/dashboard`);
      } catch (e) {
        this.setState({
          isSubmitting: false,
        });

        if(String(e.message).includes("PreSignUp failed with error ")){
          alert(
            `${String(e.message).replace('PreSignUp failed with error ', '')}`
          );
        } else {
          alert(
            `${String(e.message)} Contact support using the chat window in the bottom right for assistance`
          );
        }
      }
    } else {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  renderHeader = () => {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <Grid container justify="center">
          <Grid item xs={12} md={6} xl={4}>
            <Typography
              align="center"
              variant="headline"
              className={classes.pageTitle}
              style={{
                fontWeight: "bold",
                marginBottom: "1rem",
                color: "white",
              }}
            >
              Welcome To FanFirst!
            </Typography>

            <Typography
              align="center"
              variant="subheading"
              style={{
                color: "white",
                fontSize: "0.8rem"
              }}
            >
              FanFirst is your go to choice for an optimal buying and selling experience. 
              Buyers never pay fees and sellers pay a 9% commision.<br />
              Sellers remember: the price you list is the price the buyer pays!
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderForm() {
    const { classes } = this.props;
    const { isSubmitting } = this.state;

    return (
      <React.Fragment>
        <Button
          variant="contained"
          href="/login"
          color="secondary"
          size="medium"
          fullWidth
        >
          Login Here
        </Button>
        <Typography
          variant="caption"
          align="center"
          style={{ margin: "1rem" }}
        >
          or
        </Typography>
        <Typography variant="title" style={{ marginBottom: "1rem" }}>
          Create Your FansFirst Account
        </Typography>
        <Typography
          variant="caption"
          style={{
            marginBottom: "1.5rem",
            fontWeight: "bold",
            fontSize: 12,
            lineHeight: "16px",
          }}
        >
          IMPORTANT NOTE: We do not accept hard copies or PDFs. Only mobile
          transfers are accepted. Please email at{" "}
          <a href="mailto:tix@fansfirst.ca">tix@fansfirst.ca</a> or text/call{" "}
          <a href="tel:1-403-768-2298">1-403-768-2298</a> for more details.
        </Typography>
        <form onSubmit={this.onSubmit}>
          <FormControl error={!this.state.isValidName} fullWidth={true}>
            <TextField
              id="fullName"
              placeholder="Full Name"
              className={
                this.state.isValidName
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.fullName}
              onChange={this.handleChange("fullName")}
              margin="dense"
              disabled={isSubmitting}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
            {!this.state.isValidName ? (
              <FormHelperText>Enter your full name</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl error={!this.state.isValidEmail} fullWidth={true}>
            <TextField
              id="email"
              placeholder="Email Address"
              className={
                this.state.isValidEmail
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.email}
              onChange={this.handleChange("email")}
              margin="dense"
              disabled={isSubmitting}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
            {!this.state.isValidEmail ? (
              <FormHelperText>Enter valid email</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl error={!this.state.isValidMobileNo} fullWidth={true}>
            <TextField
              id="mobileNo"
              placeholder="Mobile No"
              fullWidth={true}
              className={
                this.state.isValidMobileNo
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.mobileNo}
              onChange={this.handleChange("mobileNo")}
              margin="dense"
              disabled={isSubmitting}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                startAdornment: (
                  <span style={{ fontSize: ".875rem", marginRight: ".5rem" }}>
                    +1
                  </span>
                ),
                inputComponent: MobileNoCustomInput,
              }}
            />
            {!this.state.isValidMobileNo ? (
              <FormHelperText>Please enter a valid Canadian mobile phone number (no VOIP or landlines).</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl error={!this.state.isValidPassword} fullWidth={true}>
            <TextField
              id="password"
              placeholder="Password"
              type="password"
              className={
                this.state.isValidPassword
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.password}
              onChange={this.handleChange("password")}
              margin="dense"
              disabled={isSubmitting}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
            {!this.state.isValidPassword ? (
              <FormHelperText>
                Password must be at least 6 characters
              </FormHelperText>
            ) : null}
          </FormControl>

          <FormControl
            error={!this.state.isValidConfirmPassword}
            fullWidth={true}
          >
            <TextField
              id="password"
              placeholder="Confirm Password"
              type="password"
              className={
                this.state.isValidConfirmPassword
                  ? classes.textField
                  : classes.textFieldError
              }
              value={this.state.confirmPassword}
              onChange={this.handleChange("confirmPassword")}
              margin="dense"
              disabled={isSubmitting}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
            {!this.state.isValidConfirmPassword ? (
              <FormHelperText>Passwords do not match</FormHelperText>
            ) : null}
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            style={{ margin: "1rem 0" }}
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Create Account"}
          </Button>
          <Typography variant="caption" color="textSecondary" align="center">
            By creating your FansFirst account, you have read and agreed to{" "}
            <a
              style={{ color: "#2196f3", textDecoration: "none" }}
              href="/legal"
              target="_blank"
            >
              FansFirst Terms and Conditions
            </a>
            .
          </Typography>
        </form>
      </React.Fragment>
    );
  }

  render() {
    const currentURL = window.location.href;
    return (
      <div>
        <Helmet>
          <title>Sell Your Tickets at fansfirst.ca | fansfirst.ca</title>
          <meta
            name="description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta
            name="keywords"
            content={`sell tickets, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`}
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Sell Your Tickets at fansfirst.ca" />
          <meta
            property="og:description"
            content="Sell your Tickets at fansfirst.ca. Listing your ticket for sale on FanFirst gives you
    access to potential buyers and allows you to get your desired price! The price you list is the price the
    buyer pays."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
           {/* X-XSS-Protection */}
           <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
        </Helmet>
        {this.renderHeader()}
        <main>
          <Grid container justify="center">
            <Grid item xs={12} md={4} xl={3}>
              {this.renderForm()}
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(SignUp);
