import React, { Component } from 'react';
import {
  withStyles, 
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Typography,
  Slide,
} from '@material-ui/core';
import { renderZoneName, renderRowName, cflTeamCheck, nhlTeamCheck, mlsTeamCheck  } from "../libs/shared/seat-helpers";
import { getTeamName } from "../libs/shared/team-helper";
import moment from "moment-timezone";

const styles = (theme) => ({
  statusInfoDialog: {
    margin: "auto",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    formContainer: {
      padding: "0 1rem",
    },
  },
  infoTitle: {
    color: "#2196f3",
    fontSize: "1.5rem",
  },
  infoText: {
    fontSize: "0.95rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    lineHeight: 1.5,
  },
  infoTextBold: {
    fontSize: "0.95rem",
    fontWeight: "600",
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  infoDetails: {
    fontSize: "0.95rem",
  },
  textRightRed: {
    fontSize: "0.95rem",
    textAlign: "right",
    color: "red",
  },
  textRight: {
    fontSize: "0.95rem",
    textAlign: "right",
  },
  infoSubTitle: {
    color: "#e03a3e",
    fontSize: "1.2rem",
    fontWeight: "600",
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  dividerInfo: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  paymentContainer: {
    display: "flex",
  },
});

const emailActions = {
  "calgary-flames": {
    link: "https://am.ticketmaster.com/flames",
    action: "Manage Events"
  },
  "edmonton-oilers": {
    link: "https://am.ticketmaster.com/oilers",
    action: "My Events"
  },
  "vancouver-canucks": {
    link: "https://am.ticketmaster.com/canucks",
    action: "Tickets"
  },
  "winnipeg-jets": {
    link: "https://am.ticketmaster.com/tnse",
    action: "My Events"
  },
  "toronto-maple-leafs": {
    link: "https://am.ticketmaster.com/mapleleafs",
    action: "Manage Tickets"
  },
  "ottawa-senators": {
    link: "https://am.ticketmaster.com/sse",
    action: "My Tickets"
  },
  "toronto-raptors": {
    link: "https://am.ticketmaster.com/raptors",
    action: "Manage Tickets"
  },
  "toronto-blue-jays": {
    link: "https://am.ticketmaster.com/tbjrc/",
    action: "Manage Tickets"
  },
  "edmonton-elks": {
    link: "https://am.ticketmaster.com/eeft/",
    action: "Manage Tickets"
  },
  "winnipeg-blue-bombers": {
    link: "https://am.ticketmaster.com/bombers/",
    action: "Manage Tickets"
  },
  "toronto-argonauts": {
    link: "https://am.ticketmaster.com/argonauts/",
    action: "Manage Tickets"
  },
  "calgary-stampeders": {
    link: "https://am.ticketmaster.com/stampeders/",
    action: "Manage Tickets"
  },
  "ottawa-redblacks": {
    link: "https://am.ticketmaster.com/redblacks/",
    action: "Manage Tickets"
  },
  "bc-lions": {
    link: "https://am.ticketmaster.com/bclions/",
    action: "Manage Tickets"
  },
  "saskatchewan-roughriders": {
    link: "https://am.ticketmaster.com/saskriders/",
    action: "Manage Tickets"
  },
  "hamilton-tigercats": {
    link: "https://am.ticketmaster.com/hamilton/",
    action: "Manage Tickets"
  },
  "calgary-wranglers": {
    link: "https://am.ticketmaster.com/wranglers/",
    action: "Manage Tickets"
  },
  "manitoba-moose": {
    link: "https://am.ticketmaster.com/mbahl/",
    action: "Manage Tickets"
  },
  "vancouver-whitecaps-fc": {
    link: "https://am.ticketmaster.com/whitecaps/",
    action: "Manage Tickets"
  },
  "toronto-fc": {
    link: "https://am.ticketmaster.com/tfc/",
    action: "Manage Tickets"
  },
  "abbotsford-canucks": {
    link: "https://am.ticketmaster.com/canucksabbotsfordahl/",
    action: "Manage Tickets"
  },
  "belleville-senators": {
    link: "https://am.ticketmaster.com/bellevillesens/",
    action: "Manage Tickets"
  },
  "toronto-marlies": {
    link: "https://am.ticketmaster.com/marlies/",
    action: "Manage Tickets"
  }
};

const getAction = (homeTeamSlug) => {
  return emailActions[homeTeamSlug] || {};
};

const Transition = (props) => {
    return <Slide direction="up" {...props} />;
  }
class StatusInfoDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null,
    };
  }

  componentWillMount() {
    const { order } = this.props;
    this.setState({ order });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.order !== this.props.order) {
      const { order } = nextProps;
      this.setState({ order });
    }
  }

  renderInfo(order) {
    const { classes } = this.props;
    const orderDate = moment.tz(Number(order.createdAt), "America/Edmonton").format("MMM DD, YYYY h:mm A");
    const cardNumber = order.card ? order.card.number.slice(-4) : "XXXX";
    const transferMethod = order.transferredDetails ? order.transferredDetails.transferMethod : null;
    const actionEmail = getAction(order.game.homeTeamSlug);
    const homeTeamSlug = order.game ? order.game.homeTeamSlug : "";
  
    if (order.isRefunded) {
      return (
        <>
          <Typography variant="title" gutterBottom className={classes.infoTitle}>
            Order was Refunded
          </Typography>
          <Typography className={classes.infoText}>
            Your order was refunded to the credit card ending in <strong>{cardNumber}</strong> on <strong>{orderDate}</strong>{" "}
            It can take up to 7 business days to appear on your online transaction breakdown. 
            This is a function of the receiving credit card and not FansFirst.
          </Typography>
        </>
      );
    }
  
    let infoText;
    let message;
    if (order.isSent) {
      if(order.isInstantDelivery) {
        return (
          <>
            <Typography variant="title" gutterBottom className={classes.infoTitle}>
              Tickets have been transferred
            </Typography>
            <Typography className={classes.infoText}>
              Your tickets are on their way. The season ticket holder has chosen FansFirst's Insta-delivery option: the season ticket holder has already sent the tickets to our system and they will be delivered right away during business hours (within a few minutes).
            </Typography>
            <Typography className={classes.infoText}>
              If you have any concerns please text us anytime <a href="tel:1-403-768-2298" target="_blank" style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a>.
            </Typography>
          </>
        );
      
      } else {
        if (transferMethod === 'Account Manager') {
          infoText = (
            <div className={classes.infoText}>
              Your tickets have been transferred to <strong>{order.email}</strong> through the team's account manager system. Don't see the email? No problem! Just follow these simple instructions:
              <ul>
                <li>Log on to <a href={actionEmail.link} target="_blank" style={{ color: "#2196f3", textDecoration: "none" }}>{actionEmail.link}</a></li>
                <li>Make sure you use <strong>{order.email}</strong></li>
                <li>Then click <strong>{actionEmail.action || "Manage Ticket"}</strong></li>
                <li>You'll see a pop-up that asks you to accept the tickets</li>
              </ul>
            </div>
          );
        } else if (transferMethod === 'Ticketmaster') {
          infoText = (
            <>
              <Typography className={classes.infoText}>
                Your tickets have been transferred to <strong>{order.email}</strong> through ticketmaster.ca. 
                You will see an email that has the subject line “FansFirst sent you ticket(s) for …”. Simply click the Accept Tickets button within that email, login to your Ticketmaster account and your tickets will be visible.
              </Typography>
              <Typography className={classes.infoText}>
                If you have already accepted the tickets and can't find them, they can be viewed here: {" "}
                <a href="https://www.ticketmaster.ca/user/orders" target="_blank" style={{ color: "#2196f3", textDecoration: "none" }}>https://www.ticketmaster.ca/user/orders</a>
              </Typography>
            </>
          );
        } else if (transferMethod === 'Ballpark') {
          infoText = (
            <>
              <Typography className={classes.infoText}>
                Your tickets have been transferred to <strong>{order.email}</strong> through the MLB Ballpark app! 
                To retrieve them you need to login to the MLB Ballpark app on your iPhone or Android phone and login or create an account with the email address <strong>{order.email}</strong>.
              </Typography>             
            </>
          );
        } else {
          infoText = (
            <>
              <Typography className={classes.infoText}>
                Your tickets purchased on FansFirst have been sent to you via Mobile Ticketing. You will see an email with the link embedded. Please note that screenshots will not work.
              </Typography>
              <Typography className={classes.infoText}>
                If you cannot see the email, please text us at <a href="tel:1-403-768-2298" target="_blank" style={{ color: "#2196f3", textDecoration: "none" }}>403.768.2298</a> or email us at <a href='mailto:tix@fansfirst.ca' style={{ color: "#2196f3", textDecoration: "none" }}>tix@fansfirst.ca</a>.
              </Typography>
            </>
          );
        }
    
        return (
          <>
            <Typography variant="title" gutterBottom className={classes.infoTitle}>
              Tickets have been transferred
            </Typography>
            {infoText}
            <Typography className={classes.infoText}>
              Please make sure you login and access your tickets ON YOUR PHONE at least a few hours before the event starts. Our support teams are usually at their busiest in the hour leading up to a game, so if you need any assistance there could be long hold times.
            </Typography>
          </>
        );
      }

    } else {

      if (order.game.isTbd || order.game.isPlayoffs) {
        message = (
          <Typography className={classes.infoText}>
            The opponent, date, and time are all subject to change and will be updated as soon as the official schedule is released by the league.<br /><br />
            If the game you have purchased doesn't occur (the series ends early or the team doesn't make it to a particular round of the playoffs) then your transaction is cancelled and you get a full refund.<br /><br />
            Please note that all sales are final, and declining the transfer does not cancel your order.
          </Typography>
        );
      } else if (order.game.isTbd) {
        message = (
          <Typography className={classes.infoText}>
            The opponent, date, and time are all subject to change and will be updated as soon as the official schedule is released by the league.<br /><br />
            If the game you have purchased doesn't occur (the series ends early or the team doesn't make it to a particular round of the playoffs) then your transaction is cancelled and you get a full refund.
          </Typography>
        );
      } else if (order.isInstantDelivery) {
        message = (
          <Typography className={classes.infoText}>
            Your tickets are on their way. The season ticket holder has chosen FansFirst’s Insta-delivery option: the season ticket holder has already sent the tickets to our system and they will be delivered right away during business hours (within a few minutes). If you have any concerns please text us anytime 1-403-768-2298.
          </Typography>
        );
      } else if (cflTeamCheck(homeTeamSlug) || mlsTeamCheck(homeTeamSlug) || ["toronto-blue-jays", "toronto-raptors", "toronto-maple-leafs", "ottawa-senators"].includes(homeTeamSlug)) {
        message = (
          <>
            <Typography className={classes.infoText}>
              Your {getTeamName(homeTeamSlug)} tickets are on their way. The ticket holder has been notified and will deliver the tickets as soon as they can. Delivery times range from
            </Typography>
            <div className={classes.infoText}>
              <ul>
                <li>a few minutes to an hour if the ticket(s) are purchased on game day;</li>
                <li>a few hours or by the end of the day if the ticket(s) are purchased the day before game day; or</li>
                <li>one day prior to the game if the purchased tickets are further away on the calendar (this is the absolute latest they are expected to arrive, most sellers transfer tickets sooner).</li>
              </ul>
            </div>
          </>
        );
      } else {
        // for Canucks, Flames, Oilers and Jets.
        message = (
          <>
            <Typography className={classes.infoText}>
              Your tickets are on their way. The season ticket holder has been notified and will deliver the tickets as soon as they can. Delivery times range from
            </Typography>
            <div className={classes.infoText}>
              <ul>
                <li>a few minutes to an hour if the ticket(s) are purchased on game day;</li>
                <li>a few hours or by the end of the day if the ticket(s) are purchased the day before game day; or</li>
                <li>up to 24 to 36 hours if the purchased tickets are further away on the calendar.</li>
              </ul>
            </div>
          </>
        );
      }
      
      return (
        <>
          <Typography variant="title" gutterBottom className={classes.infoTitle}>
            Still waiting for the seller
          </Typography>
          {/* <Typography className={classes.infoText}>
            The seller has been notified and will deliver the tickets as soon as they can. Delivery times range from:
          </Typography> */}
          {message}
          <Typography className={classes.infoText}>
            Keep in mind FansFirst does not own the tickets.
          </Typography>
          <Typography className={classes.infoText}>
            Don't worry - if the seller informs us that the tickets are no longer available (usually right away) you will receive a FULL refund plus a coupon to be used for a future purchase. 
            If you have any concerns or want to check on the status of your tickets, please text us anytime <a href="tel:1-403-768-2298" target="_blank" style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a>.
          </Typography>
        </>
      );
    }
  }

  discountedSalesAmount = (order) => {
    if (isNaN(parseInt(order.noOfSeats)) || isNaN(parseFloat(order.ticketPrice)) || isNaN(parseFloat(order.discountValue))) {
      return 0;
    }
    return (parseInt(order.noOfSeats) * parseFloat(order.ticketPrice) - parseFloat(order.discountValue)).toFixed(2);
  };

  renderPaymentInfo(order) {
    const { classes } = this.props;
    const isCoupon = parseFloat(order.discountValue) > 0.00;
    const subTotal = (parseFloat(order.ticketPrice) * parseInt(order.noOfSeats)).toFixed(2);
    const discountedSalesAmount = this.discountedSalesAmount(order);
    return (
      <>
        {(!(order.isRefunded) && (
          <Typography className={classes.infoTextBold}>
            Please note that all sales are final, and declining the transfer does not cancel your order.
          </Typography>
        ))}
        <Typography variant="subheading" className={classes.infoSubTitle}>
          {order.isRefunded ? 'Your REFUND Details' : 'Your Order Details'}
        </Typography>
        <Typography className={classes.infoDetails}>
          Order Number: {order.trackingNumber}
        </Typography>
        <Typography className={classes.infoDetails}>
          Amount: CAD ${(order.ticketPrice * order.noOfSeats).toFixed(2)}
        </Typography>
        <Typography className={classes.infoDetails}>
          {order.noOfSeats} Ticket(s)
        </Typography>
        <Typography className={classes.infoDetails}>
          {order.game.longName}
        </Typography>
        {(!(order.game.isTbd) && (
          <Typography className={classes.infoDetails}>
            {moment
              .tz(Number(order.game.date), order.game.timezone)
              .format("ddd, MMM DD YYYY [at] h:mm A")}
          </Typography>
        ))}
        <Typography className={classes.infoDetails}>
          {renderZoneName(order.seat.zone, order.seat.zoneNo, order.seat.row, order.game.homeTeamSlug, order.game.isSpecial)}{" "}
          Row {renderRowName(order.seat.zone, order.seat.row, order.game.homeTeamSlug, order.game.isSpecial)}
        </Typography>

        <Typography variant="subheading" className={classes.infoSubTitle}>
          Payment Information
        </Typography>
        <Grid
          className={classes.paymentContainer}
          container
        >
          <Grid item xs={6} md={10}>
            <Typography className={classes.infoDetails}>
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography className={classes.textRight}>
              CAD ${subTotal}
            </Typography>
          </Grid>
          <Grid item xs={6} md={10}>
            <Typography className={classes.infoDetails}>
              Service Fee
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography className={classes.textRight}>
              CAD $0.00
            </Typography>
          </Grid>
          <Grid item xs={6} md={10}>
            <Typography className={classes.infoDetails}>
              GST (5% - only applicable on service fee)
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography className={classes.textRight}>
              CAD $0.00
            </Typography>
          </Grid>
          {isCoupon && (
            <>
              <Grid item xs={6} md={10}>
                <Typography className={classes.infoDetails}>
                  Coupon Value
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography className={classes.textRightRed}>
                -CAD ${parseFloat(order.discountValue).toFixed(2)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6} md={10}>
            <Typography className={classes.infoDetails}>
              <strong>Payment Total</strong>
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography className={classes.textRight}>
            <strong>CAD ${discountedSalesAmount}</strong>
            </Typography>
          </Grid>
        </Grid>
       
        <Divider className={classes.dividerInfo}/>
        <Typography variant="subheading" align="center" className={classes.infoSubTitle}>
          Important Instructions
        </Typography>
        <Typography  align="center" className={classes.infoText}>
          We guarantee all digitally scanned tickets. Printed copies do not
          fall under the guarantee. We encourage you to<strong> NOT </strong>print your tickets.
        </Typography>
      </>
    );
  }

  render() {
    const { isOpen, handleClose, classes } = this.props;
    const { order } = this.state;
    
    return (
      <Dialog 
        className={classes.statusInfoDialog}
        fullWidth={false}
        maxWidth="md"
        open={isOpen} 
        onClose={handleClose}
        scroll={'body'}
      >
        <DialogContent>
          <Grid
            className={classes.formContainer}
            container
          >
            <Grid item xs={12} md={12}>
              {this.state.order ?  (
                <>
                  {this.renderInfo(order)}
                  {this.renderPaymentInfo(order)}
                </>
              ) : (
                <Typography
                  style={{ margin: "2rem 0" }}
                  variant="subheading"
                  align="center"
                  color="textSecondary"
                >
                  No further info<br />
                  Please select others ticket.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(StatusInfoDialog);