export const getZones = rsr => {
  let zones = [];  
    
  var _101 = {
    path:
      'M 1810.00,1371.00 C 1810.00,1371.00 1877.00,1371.00 1877.00,1371.00 1877.00,1371.00 1877.00,1551.00 1877.00,1551.00 1877.00,1551.00 1810.00,1551.00 1810.00,1551.00 1810.00,1551.00 1810.00,1371.00 1810.00,1371.00 Z',
    attr: {
      id: '_101',
      'data-name': '101',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "101", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _102 = {
    path:
      'M 1706.00,1371.00 C 1706.00,1371.00 1785.00,1371.00 1785.00,1371.00 1785.00,1371.00 1785.00,1551.00 1785.00,1551.00 1785.00,1551.00 1706.00,1551.00 1706.00,1551.00 1706.00,1551.00 1706.00,1371.00 1706.00,1371.00 Z',
    attr: {
      id: '_102',
      'data-name': '102',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "102", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _103 = {
    path:
      'M 1615.00,1371.00 C 1615.00,1371.00 1681.00,1371.00 1681.00,1371.00 1681.00,1371.00 1681.00,1551.00 1681.00,1551.00 1681.00,1551.00 1616.00,1551.00 1616.00,1551.00 1616.00,1551.00 1616.00,1430.00 1616.00,1430.00 1616.00,1430.00 1616.00,1393.00 1616.00,1393.00 1616.00,1393.00 1615.00,1371.00 1615.00,1371.00 Z',
    attr: {
      id: '_103',
      'data-name': '103',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "103", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _104 = {
    path:
      'M 1502.00,1371.00 C 1502.00,1371.00 1592.00,1371.00 1592.00,1371.00 1592.00,1371.00 1591.00,1393.00 1591.00,1393.00 1591.00,1393.00 1591.00,1430.00 1591.00,1430.00 1591.00,1430.00 1591.00,1551.00 1591.00,1551.00 1591.00,1551.00 1502.00,1551.00 1502.00,1551.00 1502.00,1551.00 1502.00,1371.00 1502.00,1371.00 Z',
    attr: {
      id: '_104',
      'data-name': '104',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "104", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _105 = {
    path:
      'M 1358.00,1371.00 C 1358.00,1371.00 1477.00,1371.00 1477.00,1371.00 1477.00,1371.00 1477.00,1551.00 1477.00,1551.00 1477.00,1551.00 1358.00,1551.00 1358.00,1551.00 1358.00,1551.00 1358.00,1371.00 1358.00,1371.00 Z',
    attr: {
      id: '_105',
      'data-name': '105',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "105", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _106 = {
    path:
      'M 1184.00,1371.00 C 1184.00,1371.00 1333.00,1371.00 1333.00,1371.00 1333.00,1371.00 1333.00,1551.00 1333.00,1551.00 1333.00,1551.00 1184.00,1551.00 1184.00,1551.00 1184.00,1551.00 1184.00,1371.00 1184.00,1371.00 Z',
    attr: {
      id: '_106',
      'data-name': '106',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "106", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _107 = {
    path:
      'M 990.00,1371.00 C 990.00,1371.00 1159.00,1371.00 1159.00,1371.00 1159.00,1371.00 1159.00,1551.00 1159.00,1551.00 1159.00,1551.00 990.00,1551.00 990.00,1551.00 990.00,1551.00 990.00,1371.00 990.00,1371.00 Z',
    attr: {
      id: '_107',
      'data-name': '107',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "107", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _108 = {
    path:
      'M 814.00,1371.00 C 814.00,1371.00 965.00,1371.00 965.00,1371.00 965.00,1371.00 965.00,1551.00 965.00,1551.00 965.00,1551.00 814.00,1551.00 814.00,1551.00 814.00,1551.00 814.00,1371.00 814.00,1371.00 Z',
    attr: {
      id: '_108',
      'data-name': '108',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "108", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _109 = {
    path:
      'M 653.00,1371.00 C 653.00,1371.00 789.00,1371.00 789.00,1371.00 789.00,1371.00 789.00,1551.00 789.00,1551.00 789.00,1551.00 653.00,1551.00 653.00,1551.00 653.00,1551.00 653.00,1371.00 653.00,1371.00 Z',
    attr: {
      id: '_109',
      'data-name': '109',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "109", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _110 = {
    path:
      'M 548.00,1371.00 C 548.00,1371.00 628.00,1371.00 628.00,1371.00 628.00,1371.00 628.00,1551.00 628.00,1551.00 628.00,1551.00 548.00,1551.00 548.00,1551.00 548.00,1551.00 548.00,1371.00 548.00,1371.00 Z',
    attr: {
      id: '_110',
      'data-name': '110',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "110", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _111 = {
    path:
      'M 471.00,1371.00 C 471.00,1371.00 523.00,1371.00 523.00,1371.00 523.00,1371.00 523.00,1551.00 523.00,1551.00 523.00,1551.00 471.00,1551.00 471.00,1551.00 471.00,1551.00 471.00,1371.00 471.00,1371.00 Z',
    attr: {
      id: '_111',
      'data-name': '111',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "111", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _112 = {
    path:
      'M 391.00,1371.00 C 391.00,1371.00 446.00,1371.00 446.00,1371.00 446.00,1371.00 446.00,1553.00 446.00,1553.00 446.00,1553.00 391.00,1553.00 391.00,1553.00 391.00,1553.00 391.00,1371.00 391.00,1371.00 Z',
    attr: {
      id: '_112',
      'data-name': '112',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "112", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _113 = {
    path:
      'M 311.00,1371.00 C 311.00,1371.00 366.00,1371.00 366.00,1371.00 366.00,1371.00 366.00,1553.00 366.00,1553.00 366.00,1553.00 311.00,1553.00 311.00,1553.00 311.00,1553.00 311.00,1371.00 311.00,1371.00 Z',
    attr: {
      id: '_113',
      'data-name': '113',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "113", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _114 = {
    path:
      'M 231.00,1372.00 C 231.00,1372.00 264.00,1372.00 264.00,1372.00 264.00,1372.00 286.00,1371.00 286.00,1371.00 286.00,1371.00 286.00,1554.00 286.00,1554.00 286.00,1554.00 231.00,1554.00 231.00,1554.00 231.00,1554.00 231.00,1372.00 231.00,1372.00 Z',
    attr: {
      id: '_114',
      'data-name': '114',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "114", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _115 = {
    path:
      'M 36.00,1133.00 C 36.00,1133.00 189.00,1133.00 189.00,1133.00 189.00,1133.00 189.00,1258.00 189.00,1258.00 189.00,1258.00 36.00,1258.00 36.00,1258.00 36.00,1258.00 36.00,1133.00 36.00,1133.00 Z',
    attr: {
      id: '_115',
      'data-name': '115',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "115", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _116 = {
    path:
      'M 36.00,987.00 C 36.00,987.00 189.00,987.00 189.00,987.00 189.00,987.00 189.00,1112.00 189.00,1112.00 189.00,1112.00 36.00,1112.00 36.00,1112.00 36.00,1112.00 36.00,987.00 36.00,987.00 Z',
    attr: {
      id: '_116',
      'data-name': '116',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "116", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _117 = {
    path:
      'M 36.00,809.00 C 36.00,809.00 190.00,809.00 190.00,809.00 190.00,809.00 190.00,929.00 190.00,929.00 190.00,929.00 190.00,954.00 190.00,954.00 190.00,954.00 188.98,964.40 188.98,964.40 188.98,964.40 181.00,966.00 181.00,966.00 181.00,966.00 36.00,966.00 36.00,966.00 36.00,966.00 36.00,809.00 36.00,809.00 Z',
    attr: {
      id: '_117',
      'data-name': '117',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "117", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _118 = {
    path:
      'M 36.00,657.00 C 36.00,657.00 154.00,657.00 154.00,657.00 154.00,657.00 181.00,657.00 181.00,657.00 181.00,657.00 188.40,658.02 188.40,658.02 188.40,658.02 190.00,666.00 190.00,666.00 190.00,666.00 190.00,759.00 190.00,759.00 190.00,759.00 190.00,780.00 190.00,780.00 190.00,780.00 188.26,787.01 188.26,787.01 188.26,787.01 181.00,788.00 181.00,788.00 181.00,788.00 36.00,788.00 36.00,788.00 36.00,788.00 36.00,657.00 36.00,657.00 Z',
    attr: {
      id: '_118',
      'data-name': '118',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "118", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _119 = {
    path:
      'M 36.00,538.00 C 36.00,538.00 153.00,538.00 153.00,538.00 153.00,538.00 182.00,538.00 182.00,538.00 182.00,538.00 189.96,539.60 189.96,539.60 189.96,539.60 189.96,551.00 189.96,551.00 189.96,551.00 189.96,636.00 189.96,636.00 189.96,636.00 36.00,636.00 36.00,636.00 36.00,636.00 36.00,538.00 36.00,538.00 Z',
    attr: {
      id: '_119',
      'data-name': '119',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "119", zone: 'Lower Zone', zoneId: "1" },
  };
  
  var _GAnorth = {
    path:
      'M 350.00,334.00 C 350.00,334.00 1790.00,334.00 1790.00,334.00 1790.00,334.00 1790.00,450.00 1790.00,450.00 1790.00,450.00 350.00,450.00 350.00,450.00 350.00,450.00 350.00,334.00 350.00,334.00 Z',
    attr: {
      id: '_GAnorth',
      'data-name': 'GAnorth',
       opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
       'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'GA North', zoneId: "1" },
  };

  zones.push(
   _101,
   _102,
   _103,
   _104,
   _105,
   _106,
   _107,
   _108,
   _109,
   _110,
   _111,
   _112,
   _113,
   _114,
   _115,
   _116,
   _117,
   _118,
   _119,
   _GAnorth,
  );
  return zones;
};