import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FaqsContent from '../constants/FaqsContent';

const styles = theme => ({
  faqSection: {
    marginBottom: '3rem'
  },
  sectionTitle: {
    color: '#2196f3',
    marginBottom: '1.5rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  },
});

class Faqs extends Component {

  renderSection(section, index) {
    const { classes } = this.props;

    return <div className={classes.faqSection} key={index}>
      <Typography variant="title" className={classes.sectionTitle}>
        {section.title}
      </Typography>
      {
        section.faqs.map((faq, index) => {
          return (
            <ExpansionPanel key={index}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{faq.title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography dangerouslySetInnerHTML={{__html: faq.description}}>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })
      }
    </div>
  }

  render() {
    return (
      <div id="faqs" style={{ paddingTop: '2rem' }}>
        { FaqsContent.map((section, index) => this.renderSection(section, index)) }
      </div>
    );
  }
}

export default withStyles(styles)(Faqs);