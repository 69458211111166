const zones = [
    {
      label: "Lower Zone 101",
    },
    {
      label: "Lower Zone 102",
    },
    {
      label: "Lower Zone 103",
    },
    {
      label: "Lower Zone 104",
    },
    {
      label: "Lower Zone 105",
    },
    {
      label: "Lower Zone 106",
    },
    {
      label: "Lower Zone 107",
    },
    {
      label: "Lower Zone 108",
    },
    {
      label: "Lower Zone 109",
    },
    {
      label: "Lower Zone 110",
    },
    {
      label: "Lower Zone 111",
    },
    {
      label: "Lower Zone 112",
    },
    {
      label: "Lower Zone 113",
    },
    {
      label: "Lower Zone 114",
    },
    {
      label: "Lower Zone 115",
    },
    {
      label: "Lower Zone 116",
    },
    {
      label: "Lower Zone 117",
    },
    {
      label: "Lower Zone 118",
    },
    {
      label: "Lower Zone 119",
    },
    {
      label: "GA North",
    },
  ];
  
  export default zones;
  