const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const raptorZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 123:
      return "Lower Zone";
    case zoneNo > 300 && zoneNo < 325:
      return "Upper Zone";
    case zoneNo > 199 && zoneNo < 265:
      return "Suite";
    case zoneNo > 400 && zoneNo < 409:
      return "Suite";
    case zoneNo > 500 && zoneNo < 520:
      return "Suite";
    case zoneNo > 600 && zoneNo < 622:
      return "Suite";
    case zoneNo > 0 && zoneNo < 7:
      return "GBOX";
    default:
      return "";
  }
}
const lionsZoneNoMap = (zoneNo, teZone) => {
  const lowerZone = [
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "111",
    "112",
    "113",
    "114",
    "115",
    "116",
    "117",
    "118",
    "119", 
  ];
  const isSpecialZone = lowerZone.find((zone) => zone === String(zoneNo).toUpperCase()) ? true : false;
  const isOtherZone = ["GA North"].find((zone) => zone === String(zoneNo)) ? true : false;
  switch (true) {
    case isOtherZone:
      return "GA North";
    case isSpecialZone:
    case zoneNo > 200 && zoneNo < 255:
      return "Lower Zone";
    case zoneNo > 335 && zoneNo < 347:
      return "Club Seats";
    case zoneNo > 400 && zoneNo < 455:
      return "Upper Zone";
    default:
      return "";
  }
}
const whitecapsZoneNoMap = (zoneNo, teZone) => {
  const numberZone = isNaN(Number(zoneNo)) ? false : true;

  const GAZone = ['GA1'];
  const findGAZone = GAZone.find((zone) => {
    return zone === String(zoneNo).toUpperCase();
  })
  
  switch (true) {
    case zoneNo === "GA1":
      return "Lower Zone";
    case numberZone && zoneNo > 200 && zoneNo < 255:
      return "Lower Zone";
    case numberZone && zoneNo > 335 && zoneNo < 347:
      return "Middle Zone";
    case !numberZone && findGAZone:
      return "Lower Zone";
    default:
      return "";
  }
}
const bombersZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 145:
      return "Lower Zone";
    case zoneNo > 200 && zoneNo < 235:
      return "Upper Zone";
    default:
      return "";
  }
}
const argonautsZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 103 && zoneNo < 128:
      return "Lower Zone";
    case zoneNo > 202 && zoneNo < 228:
      return "Upper Zone";
    default:
      return "";
  }
}
const torontofcsZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 131:
      return "Lower Zone";
    case zoneNo > 202 && zoneNo < 228:
      return "Upper Zone";
    default:
      return "";
  }
}
const tigersZoneNoMap = (zoneNo, teZone) => {
  const numberZone = isNaN(Number(zoneNo)) ? false : true;
  const premiumZone = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6'];
  const findPremZone = premiumZone.find((zone) => {
    return zone === String(zoneNo).toUpperCase();
  })
  switch (true) {
    case numberZone && zoneNo > 100 && zoneNo < 120:
      return "Lower Zone";
    case numberZone && zoneNo > 201 && zoneNo < 219:
      return "Upper Zone";
    case !numberZone && findPremZone:
      return "Premium Zone C";
    default:
      return "";
  }
}
const redblackZoneNoMap = (zoneNo, teZone) => {
  const upperZone = [
    "XX",
    "WW",
    "VV",
    "UU",
    "TT",
    "SS",
    "RR",
    "QQ",
    "PP",
    "NN",
    "MM",
    "JJ",
    "HH",
    "GG",
    "FF",
    "EE",
    "DD",
    "CC",
    "BB",
    "AA",
  ];
  const lowerZone = [
    "Z",
    "Y",
    "X",
    "W",
    "V",
    "U",
    "T",
    "S",
    "R",
    "Q",
    "P",
    "N",
    "M",
    "L",
    "K",
    "J",
    "H",
    "G",
    "F",
    "E",
    "D",
    "C",
    "B",
    "A",
  ];
  const middleZone = [
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "111",
  ];
  const findLower = lowerZone.find((no) => no === String(zoneNo).toUpperCase());
  const findUpper = upperZone.find((no) => no === String(zoneNo).toUpperCase());
  const findMiddle = middleZone.find((no) => no === zoneNo);
  if (findLower) {
    return `Lower Zone ${String(zoneNo).toUpperCase()}`
  } else if (findUpper) {
    return `Upper Zone ${String(zoneNo).toUpperCase()}`
  } else if (findMiddle) {
    return "Middle Zone"
  } else {
    return ""
  }
}
const stampedersZoneNoMap = (zoneNo, teZone) => {
  const lowerZone = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
  ];
  const findLower = lowerZone.find((no) => no === String(zoneNo).toUpperCase());
  if (findLower) {
    return `Lower Zone`;
  } else {
    return "";
  }
}
const elksZoneNoMap = (zoneNo, teZone) => {
  const upperZone = [
    "AA2",
    "BB2",
    "CC2",
    "A2",
    "B2",
    "C2",
    "D2",
    "E2",
    "F2",
    "G2",
    "H2",
    "I2",
    "J2",
    "K2",
    "L2",
    "M2",
    "DD2",
    "EE2",
    "FF2",
    "RR2",
    "QQ2",
    "PP2",
    "Z2",
    "Y2",
    "X2",
    "W2",
    "V2",
    "U2",
    "T2",
    "S2",
    "R2",
    "Q2",
    "P2",
    "O2",
    "N2",
    "OO2",
    "NN2",
    "MM2",
  ];
  const lowerZone = [
      "AA",
      "BB",
      "CC",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "DD",
      "EE",
      "FF",
      "PDFF",
      "GG",
      "HH",
      "JJ",
      "KK",
      "LL",
      "MM",
      "NN",
      "PDNN",
      "OO",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "PP",
      "QQ",
      "RR",
  ];
  const findUper = upperZone.find((no) => no === String(zoneNo).toUpperCase());
  const findLower = lowerZone.find((no) => no === String(zoneNo).toUpperCase());
  if (findUper) {
    return `Upper Zone ${String(zoneNo).replace(/[0-9]/g, '').toUpperCase()}`;
  } else if (findLower) {
    return `Lower Zone ${String(zoneNo).toUpperCase()}`;
  } else {
    return "";
  }
}
const roughridersZoneNoMap = (zoneNo, teZone) => {
  const middleZoneList = ['313A', '314A', '315A', '316A'];
  const isMidleExist = middleZoneList.find((zone) => zone === String(zoneNo).toUpperCase()) ? true : false;
  switch (true) {
    case zoneNo > 105 && zoneNo < 148:
      return "Lower Zone";
    case zoneNo > 223 && zoneNo < 244:
      return "Middle Zone";
    case zoneNo > 307 && zoneNo < 321:
      return "Middle Zone";
    case isMidleExist:
      return "Middle Zone";
    case zoneNo > 509 && zoneNo < 544:
      return "Upper Zone";
      case zoneNo > 609 && zoneNo < 644:
      return "Upper Zone";
    default:
      return "";
  }
}
const flamesZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case (zoneNo > 0 && zoneNo < 19):
      return "Press Level";
    case (zoneNo > 200 && zoneNo < 229):
      return "Second Level";
    case (zoneNo > 100 && zoneNo < 115):
      return "Lower Bowl";
    case (zoneNo > 114 && zoneNo < 123):
      return "Lower Club";
    default:
      return "";
  }
}

const oilersZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 200 && zoneNo < 235:
      return "Upper Bowl";
    case zoneNo > 0 && zoneNo < 31:
      return "Loge";
    case zoneNo > 100 && zoneNo < 135:
      return "Lower Bowl";
    default:
      return "";
  }
}

const senatorsZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 121:
      return "Lower Zone";
    case zoneNo > 200 && zoneNo < 229:
      return "Middle Zone";
    case zoneNo > 300 && zoneNo < 329:
      return "Upper Zone";
    case zoneNo > 400 && zoneNo < 480:
      return "Suite";
    default:
      return "";
  }
}

const canucksZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 103 && zoneNo < 109:
      return "Club Lower";
    case zoneNo > 100 && zoneNo < 103:
      return "Lower Bowl";
    case zoneNo > 108 && zoneNo < 123:
      return "Lower Bowl";
    case zoneNo > 300 && zoneNo < 331:
      return "Upper Bowl";
    case zoneNo > 416 && zoneNo < 420:
      return "Upper Bowl - Balcony";
    case zoneNo > 399 && zoneNo < 403:
      return "Sports Bar";
    default:
      return "";
  }
}

const jetsZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 128:
      return "Lower Zone";
    case zoneNo > 200 && zoneNo < 229:
      return "Middle Zone";
    case zoneNo > 300 && zoneNo < 331:
      return "Upper Zone";
    default:
      return "";
  }
}

const leafsZoneNoMap = raptorZoneNoMap;

const blueJaysZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo >= 1 && zoneNo <= 5:
      return "Lower Zone";
    case zoneNo >= 16 && zoneNo <= 32:
      return "Lower Zone";
    case zoneNo > 100 && zoneNo < 149:
      return "Lower Zone";
    case zoneNo > 203 && zoneNo < 244:
      return "Middle Zone";
    case zoneNo > 507 && zoneNo < 541:
      return "Upper Zone"
    default:
      return "---";
  }
}

const wranglersZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case (zoneNo > 0 && zoneNo < 19):
      return "Press Level";
    case (zoneNo > 200 && zoneNo < 229):
      return "Second Level";
    case (zoneNo > 100 && zoneNo < 115):
      return "Lower Bowl";
    case (zoneNo > 114 && zoneNo < 123):
      return "Lower Club";
    default:
      return "";
  }
}

const mooseZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 128:
      return "Lower Zone";
    case zoneNo > 200 && zoneNo < 229:
      return "Middle Zone";
    case zoneNo > 300 && zoneNo < 331:
      return "Upper Zone";
    default:
      return "";
  }
}

const abbotsfordCanucksZoneNoMap = (zoneNo, teZone) => {
  return "Lower Zone";
}

const bellevilleSenatorsZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case zoneNo > 100 && zoneNo < 118:
      return "Lower Zone";
    case zoneNo > 300 && zoneNo < 320:
      return "Upper Zone";
    default:
      return "";
  }
}

const torontoMarliesZoneNoMap = (zoneNo, teZone) => {
  return "Lower Zone";
}

const roughnecksZoneNoMap = (zoneNo, teZone) => {
  switch (true) {
    case (zoneNo > 0 && zoneNo < 19):
      return "Press Level";
    case (zoneNo > 200 && zoneNo < 229):
      return "Second Level";
    case (zoneNo > 100 && zoneNo < 115):
      return "Lower Bowl";
    case (zoneNo > 114 && zoneNo < 123):
      return "Lower Club";
    default:
      return "";
  }
}

export const getTeamZone = (teamSlug, teZone, zoneNo) => {
  switch (teamSlug) {
    case "calgary-flames": {
      return flamesZoneNoMap(Number(zoneNo), teZone);
    }

    case "edmonton-oilers": {
      return oilersZoneNoMap(Number(zoneNo), teZone);
    }

    case "vancouver-canucks": {
      return canucksZoneNoMap(Number(zoneNo), teZone);
    }

    case "winnipeg-jets": {
      return jetsZoneNoMap(Number(zoneNo), teZone);
    }

    case "toronto-maple-leafs": {
      return leafsZoneNoMap(Number(zoneNo), teZone);
    }

    case "ottawa-senators": {
      return senatorsZoneNoMap(Number(zoneNo), teZone);
    }

    case "toronto-raptors": {
      return raptorZoneNoMap(Number(zoneNo), teZone);
    }

    case "toronto-blue-jays": {
      return blueJaysZoneNoMap(Number(zoneNo), teZone);
    }

    case "bc-lions": {
      return lionsZoneNoMap(zoneNo, teZone);
    }

    case "ottawa-redblacks": {
      return redblackZoneNoMap(zoneNo, teZone);
    }

    case "toronto-argonauts": {
      return argonautsZoneNoMap(Number(zoneNo), teZone);
    }

    case "hamilton-tigercats": {
      return tigersZoneNoMap(zoneNo, teZone);
    }

    case "grey-cup": {
      return bombersZoneNoMap(Number(zoneNo), teZone);
    }

    case "calgary-stampeders": {
      return stampedersZoneNoMap(zoneNo, teZone);
    }

    case "winnipeg-blue-bombers": {
      return bombersZoneNoMap(Number(zoneNo), teZone);
    }

    case "edmonton-elks": {
      return elksZoneNoMap(zoneNo, teZone);
    }

    case "saskatchewan-roughriders": {
      return roughridersZoneNoMap(Number(zoneNo), teZone);
    }

    case "calgary-wranglers": {
      return wranglersZoneNoMap(Number(zoneNo), teZone);
    }

    case "manitoba-moose": {
      return mooseZoneNoMap(Number(zoneNo), teZone);
    }

    case "vancouver-whitecaps-fc": {
      return whitecapsZoneNoMap(Number(zoneNo), teZone);
    }
    
    case "toronto-fc": {
      return torontofcsZoneNoMap(Number(zoneNo), teZone);
    }

    case "abbotsford-canucks": {
      return abbotsfordCanucksZoneNoMap(Number(zoneNo), teZone);
    }

    case "belleville-senators": {
      return bellevilleSenatorsZoneNoMap(Number(zoneNo), teZone);
    }

    case "toronto-marlies": {
      return torontoMarliesZoneNoMap(Number(zoneNo), teZone);
    }

    case "calgary-hitmen": {
      return flamesZoneNoMap(Number(zoneNo), teZone);
    }

    case "belleville-senators-special": {
      return senatorsZoneNoMap(Number(zoneNo), teZone);
    }
      
    case "calgary-roughnecks": {
      return roughnecksZoneNoMap(Number(zoneNo), teZone);
    }

    default: {
      return "";
    }
  }
};

export const getNonAlcoholLabel = (seat) => {
  switch (seat.gameDetails.homeTeamSlug) {
    case "calgary-stampeders": {
      const isNonAlcoholZone = seat.zone === 'Lower Zone L';
      return  isNonAlcoholZone ? " (Family Zone - No Alcohol)" : ''
    }
  
    default:
      return "";
  }
}
