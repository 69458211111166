const normalizedZoneMapSpecial = {
  // use edmonton elks
  "edmonton-oilers": {
    "Upper Zone A": "pressLevel",
    "Upper Zone B": "pressLevel",
    "Upper Zone C": "pressLevel",
    "Upper Zone D": "pressLevel",
    "Upper Zone E": "pressLevel",
    "Upper Zone F": "pressLevel",
    "Upper Zone G": "pressLevel",
    "Upper Zone H": "pressLevel",
    "Upper Zone I": "pressLevel",
    "Upper Zone J": "pressLevel",
    "Upper Zone K": "pressLevel",
    "Upper Zone L": "pressLevel",
    "Upper Zone M": "pressLevel",
    "Upper Zone N": "pressLevel",
    "Upper Zone O": "pressLevel",
    "Upper Zone P": "pressLevel",
    "Upper Zone Q": "pressLevel",
    "Upper Zone R": "pressLevel",
    "Upper Zone T": "pressLevel",
    "Upper Zone U": "pressLevel",
    "Upper Zone V": "pressLevel",
    "Upper Zone W": "pressLevel",
    "Upper Zone X": "pressLevel",
    "Upper Zone Y": "pressLevel",
    "Upper Zone Z": "pressLevel",
    "Upper Zone AA": "pressLevel",
    "Upper Zone BB": "pressLevel",
    "Upper Zone CC": "pressLevel",
    "Upper Zone DD": "pressLevel",
    "Upper Zone EE": "pressLevel",
    "Upper Zone FF": "pressLevel",
    "Upper Zone MM": "pressLevel",
    "Upper Zone NN": "pressLevel",
    "Upper Zone OO": "pressLevel",
    "Upper Zone PP": "pressLevel",
    "Upper Zone QQ": "pressLevel",
    "Upper Zone RR": "pressLevel",
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "Lower Zone C": "lowerBowl",
    "Lower Zone D": "lowerBowl",
    "Lower Zone E": "lowerBowl",
    "Lower Zone F": "lowerBowl",
    "Lower Zone G": "lowerBowl",
    "Lower Zone H": "lowerBowl",
    "Lower Zone I": "lowerBowl",
    "Lower Zone J": "lowerBowl",
    "Lower Zone K": "lowerBowl",
    "Lower Zone L": "lowerBowl",
    "Lower Zone M": "lowerBowl",
    "Lower Zone N": "lowerBowl",
    "Lower Zone O": "lowerBowl",
    "Lower Zone P": "lowerBowl",
    "Lower Zone Q": "lowerBowl",
    "Lower Zone R": "lowerBowl",
    "Lower Zone T": "lowerBowl",
    "Lower Zone U": "lowerBowl",
    "Lower Zone V": "lowerBowl",
    "Lower Zone W": "lowerBowl",
    "Lower Zone X": "lowerBowl",
    "Lower Zone Y": "lowerBowl",
    "Lower Zone Z": "lowerBowl",
    "Lower Zone AA": "lowerBowl",
    "Lower Zone BB": "lowerBowl",
    "Lower Zone CC": "lowerBowl",
    "Lower Zone DD": "lowerBowl",
    "Lower Zone EE": "lowerBowl",
    "Lower Zone FF": "lowerBowl",
    "Lower Zone GG": "lowerBowl",
    "Lower Zone HH": "lowerBowl",
    "Lower Zone JJ": "lowerBowl",
    "Lower Zone KK": "lowerBowl",
    "Lower Zone LL": "lowerBowl",
    "Lower Zone MM": "lowerBowl",
    "Lower Zone NN": "lowerBowl",
    "Lower Zone OO": "lowerBowl",
    "Lower Zone PP": "lowerBowl",
    "Lower Zone QQ": "lowerBowl",
    "Lower Zone RR": "lowerBowl",
    "Twisted Tea Party Deck": "lowerBowl",
    "Coors Light Party Deck": "lowerBowl",
    "Rooftop Patio": "lowerBowl",
    "ENDZONE": "lowerBowl"
  },
  // use vancouver canucks
  "toronto-raptors": {
    "Upper Bowl": "pressLevel",
    "Upper Bowl - No Alcohol": "pressLevel",
    "Upper Bowl - Balcony": "pressLevel",
    "WC 311": "pressLevel",
    "WC 317": "pressLevel",
    "WC 400E": "pressLevel",
    "WC 400S": "pressLevel",
    "Lower Bowl": "secondLevel",
    "Lower Bowl - No Alcohol": "secondLevel",
    "Club Lower": "lowerBowl",
    "Sports Bar": "lowerBowl",
  },
  // use bc lions
  "vancouver-whitecaps-fc": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "secondLevel",
    "Club Seats": "secondLevel",
    "Lower Zone": "lowerBowl",
    "The Den": "lowerBowl"
  },
  // bc lions special - victoria stadium
  "bc-lions": {
    "Lower Zone 101": "lowerBowl",
    "Lower Zone 102": "lowerBowl",
    "Lower Zone 103": "lowerBowl",
    "Lower Zone 104": "lowerBowl",
    "Lower Zone 105": "lowerBowl",
    "Lower Zone 106": "lowerBowl",
    "Lower Zone 107": "lowerBowl",
    "Lower Zone 108": "lowerBowl",
    "Lower Zone 109": "lowerBowl",
    "Lower Zone 110": "lowerBowl",
    "Lower Zone 111": "lowerBowl",
    "Lower Zone 112": "lowerBowl",
    "Lower Zone 113": "lowerBowl",
    "Lower Zone 114": "lowerBowl",
    "Lower Zone 115": "lowerBowl",
    "Lower Zone 116": "lowerBowl",
    "Lower Zone 117": "lowerBowl",
    "Lower Zone 118": "lowerBowl",
    "Lower Zone 119": "lowerBowl",
    "GA North": "lowerBowl"
  },
  "vancouver-canucks": {
    "Lower Zone": "lowerBowl",
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "ADA": "lowerBowl"
  },
  "toronto-marlies": {
    "Upper Zone": "pressLevel",
    "Lower Zone": "secondLevel",
    Suite: "lowerBowl",
    "Suite A": "lowerBowl",
    GBOX: "lowerBowl",
    Molson: "lowerBowl"
  },
  "belleville-senators": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "lowerBowl",
    "Lower Zone": "secondLevel",
    "Lower Zone C": "secondLevel",
    "Lodge": "pressLevel",
    "Ledge": "pressLevel",
    "Suite": "pressLevel",
    "Suite A": "pressLevel",
    "Suite B": "pressLevel",
    "TLGTRA": "pressLevel",
    "TLGTRB": "pressLevel",
},
};

export default normalizedZoneMapSpecial;