const faqs = [
  {
    title: "General FAQs",
    faqs: [
      {
        title: "What is FansFirst?",
        description:
          "FansFirst is where real fans buy and sell sporting event tickets. We are the only Canadian no-fee ticket marketplace – and we mean no fees; we don’t include fees in our prices like some other sites. We offer cheaper prices, safe and secure transactions, with real people ready and willing to answer all your questions. We are a site made by fans, for fans.",
      },
      {
        title: "Is FansFirst safe and secure?",
        description:
          "Yes! We guarantee that your purchase is safe, protected, and that you will receive your tickets on time. Any problems that might arise will be easily dealt with as we are available anytime through our email tix@fansfirst.ca or by calling/texting 1-403-768-2298",
      },
      {
        title: "Am I buying directly from the team?",
        description:
          "No. When buying tickets on FansFirst, you are purchasing tickets on a secondary marketplace from other ticket holders. FansFirst is not affiliated with any of the team’s tickets sold on fansfirst.ca.",
      },
      {
        title: "Does FansFirst hold any tickets?",
        description:
          "No. FansFirst does not hold an inventory of tickets. We are not ticket brokers.",
      },
      {
        title: "Will the seller have access to my information?",
        description:
          "No, the seller will not have access to any of your information. Every aspect of the transaction goes through FansFirst’s secure platform.",
      },
      {
        title: "For which sports do you sell tickets?",
        description:
          "<ul><li><strong>NHL Tickets:</strong> Calgary Flames, Edmonton Oilers, Winnipeg Jets, Vancouver Canucks, Toronto Maple Leafs and Ottawa Senators.</li><li><strong>CFL Tickets:</strong> Edmonton Elks, Blue Bombers, Ottawa Redblacks, Calgary Stampeders, Hamilton Tiger-Cats, Saskatchewan Roughriders, BC Lions, and Toronto Argonauts.</li><li><strong>MLB Tickets:</strong> Toronto Blue Jays.</li><li><strong>NBA Tickets:</strong> Toronto Raptors.</li></ul><a style=\"color: #2196f3\" href=\"https://www.fansfirst.ca/\">Explore all available events here</a>.",
      },
      {
        title: "What makes FansFirst the best ticket reseller?",
        description:
          "FansFirst sets itself apart with no service fees on ticket purchases. Whether you’re after cheap Flames Tickets, Jets Tickets, or Oilers Tickets, the price you see is the price you pay.",
      },
      {
        title: "Where can I read FansFirst reviews?",
        description:
          "Customer reviews for FansFirst tickets can be found on <a style=\"color: #2196f3\" href=\"https://www.fansfirst.ca/\">our homepage</a>, on <a style=\"color: #2196f3\" href=\"https://www.facebook.com/fansfirst.ca\">our Facebook page</a> or on our <a style=\"color: #2196f3\" href=\"https://maps.app.goo.gl/ZcA9cb8ShGSKqG3o9\">Google Business Profile.</a>",
      },
      {
        title: "What should I know about my ticket purchase?",
        description:
          "All purchases are final. Ensure you’ve selected the correct tickets before checkout.",
      },
      {
        title: "Can I transfer my tickets to someone else?",
        description:
          "Yes, once accepted you can transfer your tickets to anyone for eligible events. Contact customer support for assistance.",
      },
      {
        title: "Can I find tickets for playoff or championship games?",
        description:
          "If your team made it and there are tickets listed, the tickets are available! ",
      },
    ],
  },
  {
    title: "Mobile Link Tickets",
    faqs: [
      {
        title: "What are mobile link tickets?",
        description:
          "Mobile link tickets are transferred via a link rather than the teams account manager system. We will send you an email with the link. Simply open it on your phone and the bar code will be visible. In some cases you can add the tickets to your phone's mobile wallet, but it is not necessary to gain entry.",
      },
      {
        title: "Can I send my mobile link tickets to someone else?",
        description:
          "Yes, if you would like to send your mobile link tickets to someone else just use the share button below the bar code.",
      },
      {
        title: "How do I use my mobile link tickets at the venue?",
        description:
          "Staff at the game will simply scan the barcode on your ticket off your phone off the Team’s app to give you entry into the game.",
      }
    ],
  },
  {
    title: "Mobile Transfer",
    faqs: [
      {
        title: "What are Mobile Transfer tickets?",
        description:
          "Tickets are delivered through Mobile Transfer. Mobile Transfer refers to the process of sending digital tickets from the seller to the buyer. Once your order is confirmed, an email with additional details regarding the transfer of your tickets will be sent to you. That email will contain a link to accept your tickets. That link will bring you to the Team's ticketing site where you can sign-in or create an account. You will then be directed to the page to accept your tickets. Follow the prompts to enter additional information and agree to the terms and conditions. A confirmation page letting you know that you successfully accepted the tickets will be displayed. These Mobile Transfer tickets are the most secure possible tickets. They can exist in only one account at a time. Once they are transferred any previous hard copies or printed copies are canceled.",
      },
      {
        title: "I can’t seem to accept / claim my mobile transfer tickets",
        description:
          "If you have not located your transfer invite email, please double check your inbox, spam, and junk mail. In this email it will have instructions on how to claim and accept the tickets for your order. This email will be coming directly from the Team. If you require additional help, contact us online on our chat or call/text us at 1-403-768-2298.",
      },
      {
        title: "Can I send my Mobile Transfer tickets to someone else?",
        description:
          "Yes, you will have the ability to transfer your Mobile Transfer tickets to another individual, free of charge. You just need the name and email of the person to whom you’re transferring tickets. This person will then receive the same email you had received notifying them that tickets have been transferred to them.",
      },
      {
        title: "How do I use my Mobile Transfer tickets at the venue?",
        description:
          "Staff at the game will simply scan the barcode on your ticket off your phone off the Team’s app to give you entry into the game.",
      },
    ],
  },
  {
    title: "Buying Tickets",
    faqs: [
      {
        title: "How do I know if my order was placed?",
        description:
          "After your order is successfully processed, a new screen will load confirming an order was placed. In addition you will receive an email confirming your order and an email receipt detailing your purchase.",
      },
      {
        title: "Why can I not select the number of tickets I need for a game?",
        description:
          "Sellers ideally do not want to be stuck with a single ticket to sell, and that is the reason you might have issues selecting a certain quantity of tickets on a particular the listing. For example, you may not be able to purchase 3 tickets from a listing of 4 tickets as it would leave the seller with a single ticket. Use the “# of tickets” filter at the top of the seat selection page to display only those tickets/games that match how many tickets you wish to purchase. Once you select your quantity, any ticket listing available with the number of tickets you are looking to purchase will be displayed.",
      },
      {
        title: "How long are tickets up for sale before a game?",
        description:
          "Tickets will always be available until 1 hour after the start of each game. To ensure that you get your tickets in plenty of time ahead of the event, we advise you purchase your tickets before an event starts.",
      },
      {
        title:
          "Can I buy tickets for someone else if I’m not attending the game?",
        description:
          "You can purchase tickets for a game that you will not be attending. The name on the tickets you receive (if any) is that of the original season ticket holder. There is no issue whatsoever gaining entry into the game even if you are not the one making the purchase. After purchasing, you can easily forward your tickets to someone else through the same system used to accept your tickets.",
      },
    ],
  },
  {
    title: "Selling Tickets",
    faqs: [
      {
        title: "Is there a fee to list tickets on FansFirst?",
        description:
          "There is no fee to list tickets for sale on FansFirst! FansFirst will take a 9% commission on any sales.",
      },
      {
        title: "How do I list my tickets for sale?",
        description:
          'You can list any tickets for sale from a mobile phone, computer or laptop <a style="color: #2196f3" href="/sell">here</a>.',
      },
      {
        title: "How do I determine the price for my tickets?",
        description:
          "Check the event page to see the price of similar tickets. Ticket prices change quickly especially as game time approaches so check back often to make sure your prices are competitive. Price your tickets competitively and keep an eye on the market so you can adjust your price as you see fit. Remember, we’re all fans so please be reasonable.",
      },
      {
        title: "For how long will my listed tickets be available for sale?",
        description:
          "Tickets will always be available until 30 minutes after the start of the game. We will notify you as soon as the tickets sell or the listing is canceled. ",
      },
      {
        title: "I have season tickets; can I list multiple games?",
        description:
          "Yes! You can list as many games as you would like on FansFirst.",
      },
      {
        title: "Can I delete my listing?",
        description:
          "Yes! You can delete a FansFirst listing at any time <a target='_blank' href='https://www.fansfirst.ca/listings'>here</a>. Please always double-check your listings to ensure that they are no longer showing and have been properly deleted after removing them.",
      },
      {
        title: "If my tickets sell how do I get paid?",
        description:
          "Sellers are paid out via Interac etransfer by the end of the next business day after the event, weeks and weeks faster than other third-party marketplaces.",
      },
      {
        title: "How do I list Instant Delivery tickets?",
        description:
          "Transfer your tickets to holds@fansfirst.ca and we'll add an Instant Delivery label to your listing. We will not accept the transfer until there is a sale and you can cancel the transfer at any time.",
      },
    ],
  },
  {
    title: "Price of Tickets",
    faqs: [
      {
        title: "Who determines the price of a ticket?",
        description:
          "The owner of the ticket, the Seller, holds 100% control over the price of their tickets. FansFirst is a secondary marketplace on which those sellers can list their own tickets for sale at market value. We are the marketplace that brings the buyer and seller together. Tickets may be below or above face value of the original purchase price from the primary source.",
      },
      {
        title: "Are there any additional or hidden fees?",
        description: "There are no additional or hidden fees.",
      },
    ],
  },
  {
    title: "Ticket Listings / Delivery",
    faqs: [
      {
        title: "Why can’t I see the seat numbers?",
        description:
          "We do not require sellers to list seat numbers for privacy reasons.",
      },
      {
        title: "Will my seats be together?",
        description: "Yes, your seats will always be together.",
      },
      {
        title: "Do I need to print anything?",
        description:
          "No, we exclusively use mobile transfers, all that is required is for staff to scan the tickets off your phone. ",
      },
    ],
  },
  {
    title: "Other Ticket Questions",
    faqs: [
      {
        title:
          "There is a different name on the mobile transfer ticket, is this a problem?",
        description:
          "No problem. The name on the mobile transfer ticket(s) (if any) is that of the original season ticket holder. There is no issue whatsoever gaining entry into the game. Staff will simply scan the barcode on your ticket to give you entry into the game.",
      },
      {
        title: "I didn’t receive the right tickets, what do I do now?",
        description:
          "If the tickets you receive differ from the tickets you ordered please contact us immediately via email at tix@fansfirst.ca or call/text us at 1-403-768-2298.",
      },
      {
        title: "How are my tickets delivered?",
        description:
          "Tickets are either delivered through mobile transfer or via mobile links. <br/><br/> Mobile transfer refers to the process of sending digital tickets from the seller to the buyer. Once your mobile transfer order is confirmed, an email with additional details regarding the transfer of your tickets will be sent to you. That email will contain a link to accept your tickets. That link will bring you to the Team’s ticketing website in which you will have to sign-in or create an account. You will automatically be directed to the page to accept your tickets. Follow the prompts to enter additional information and agree to the terms and conditions. A confirmation page letting you know that you successfully accepted the tickets will be displayed. <br/><br/> Mobile link tickets are sent via email. Just click in the link in the email and add the tickets to your mobile wallet to gain access to the event. The tickets will be scanned in at the gate using the NFC chip on your phone. ",
      },
    ],
  },
  {
    title: "Payment / Checkout",
    faqs: [
      {
        title: "When will my credit card be charged?",
        description:
          "Your card will be charged and payment processed as soon as you click PAY NOW.",
      },
      {
        title: "What types of payment are accepted?",
        description:
          "We only accept credit card for ticket purchases and we distribute payments to our sellers using Interac.",
      },
    ],
  },
  {
    title: "Cancellation / Refund Policy",
    faqs: [
      {
        title: "Can I cancel my order?",
        description:
          'All sales are final. FansFirst must be fair to both buyers and sellers, and we are unable to cancel or exchange tickets on any order. If you cannot attend the game for any reason, you can list your tickets for sale on FansFirst <a style="color: #2196f3" href="/sell">here</a>',
      },
      {
        title: "Can I exchange or change my order?",
        description:
          "Sorry ticket exchanges are not possible. If you want to change the email that tickets were sent to, you can contact us before the tickets have been accepted or forward the tickets on to someone else through the Team's system.",
      },
      {
        title: "What if the game is postponed / rescheduled / cancelled?",
        description:
          "Sporting events are rarely postponed / rescheduled /cancelled. The original tickets should still be valid for the rescheduled event. If an event is cancelled and not rescheduled then a full refund of the purchase price will be issued. ",
      },
    ],
  },
  {
    title: "Hockey FAQs",
    faqs: [
      {
        title: "Which teams do you sell tickets for?",
        description:
          "FansFirst provides tickets for popular NHL teams, including:<br /><ul><li>Calgary Flames Tickets</li><li>Edmonton Oilers Tickets</li><li>Winnipeg Jets Tickets</li><li>Vancouver Canucks Game Tickets</li><li>Toronto Maple Leafs Game Tickets</li><li>Ottawa Senators Game Tickets</li></ul>",
      },
      {
        title: "Where do these teams play their home games?",
        description:
          "Here are the home venues for your favorite teams:<br /><ul><li>Calgary Flames: Scotiabank Saddledome</li><li>Edmonton Oilers: Rogers Place</li><li>Winnipeg Jets: Canada Life Centre</li><li>Vancouver Canucks: Rogers Arena</li><li>Ottawa Senators: Canadian Tire Centre</li><li>Toronto Maple Leafs: Scotiabank Arena</li></ul>",
      },
      {
        title: "Are there premium or cheap tickets available for hockey games?",
        description:
          "FansFirst offers a variety of tickets, from cheap Flames Tickets to premium Vancouver Canucks Hockey Tickets and Oilers Resale Tickets. Check event listings for availability.",
      },
      {
        title: "Can I sell my season tickets for individual NHL games?",
        description:
          "FansFirst supports ticket resales, making it easy to list Flames Game Tickets, Jets Tickets, Oilers Tickets, and more for sale.",
      }
    ],
  },
  {
    title: "Football FAQs",
    faqs: [
      {
        title: "Which CFL teams do you resell tickets for?",
        description:
          "FansFirst connects you with tickets for CFL teams such as:<br /><ul><li>Calgary Stampeders Game Tickets</li><li>Edmonton Elks Tickets</li><li>Blue Bomber Tickets</li><li>Ottawa Redblack Tickets</li><li>Toronto Argonauts Tickets</li><li>BC Lions Tickets</li><li>Saskatchewan Roughriders Tickets</li><li>Hamilton Tiger-Cats Tickets</li></ul>",
      },
      {
        title: "Where do CFL teams play their home games?",
        description:
          "<ul><li>Calgary Stampeders: McMahon Stadium in Calgary, Alberta.</li><li>Edmonton Elks: Commonwealth Stadium in Edmonton, Alberta.</li><li>Winnipeg Blue Bombers: IG Field in Winnipeg, Manitoba.</li><li>Ottawa Redblacks: TD Place Stadium in Ottawa, Ontario.</li><li>Toronto Argonauts: BMO Field in Toronto, Ontario.</li><li>BC Lions: BC Place in Vancouver, British Columbia.</li><li>Saskatchewan Roughriders: Mosaic Stadium in Regina, Saskatchewan.</li><li>Hamilton Tiger-Cats: Tim Hortons Field in Hamilton, Ontario.</li></ul>",
      },
      {
        title: "Are there group discounts for CFL games?",
        description:
          "Group discounts are not available, but with no associated fees, ​​our commitment to you is that the price you see is the price you pay.",
      },
    ],
  },
  {
    title: "Baseball FAQs",
    faqs: [
      {
        title: "Where do the Toronto Blue Jays play their home games?",
        description:
          "The Toronto Blue Jays play at the Rogers Centre in downtown Toronto.",
      },
      {
        title: "What happens if a Blue Jays game is rained out?",
        description:
          "For postponed or canceled games, FansFirst follows the event organizer's policies. Refunds or ticket transfers may be available depending on the situation.",
      },
      {
        title: "Are there promotional tickets or special giveaways?",
        description:
          "FansFirst offers Blue Jays Promotional Game Tickets and Blue Jays Single-Game Tickets for events with exclusive giveaways.",
      },
    ],
  },
];

export default faqs;
