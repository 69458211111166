import React, { Component } from 'react';
import Raphael from 'raphael';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Saddledome from '../images/roughnecks-saddledome-map.png';

import { getZones } from '../libs/roughnecks-saddledome-seat-map';
import ButtonClear from './ButtonClear';

let map = null;

const styles = theme => ({
  map: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto'
  },
  clearButtonWrapper: {
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    zIndex: 999,
    [theme.breakpoints.up('md')]: {
      width: "80%"
    },
    [theme.breakpoints.down('md')]: {
      width: "100%"
    },
  }
});

class RoughnecksSeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seats: null,
      availableSeatItems: [],
      seatItems: [],
      selectedSeats: [],
    };
  }

  createMap(seats) {
    const mapDiv = this.mapDiv;

    const height = mapDiv.clientHeight === 0 ? mapDiv.clientWidth - 32 : mapDiv.clientHeight;
    const width = mapDiv.clientHeight === 0 ? mapDiv.clientWidth - 32 : mapDiv.clientHeight;

    const map = Raphael('map', width, height);

    const initViewBoxHeight = 883 - height + height;
    const initViewBoxWidth = 883 - width + width;

    map.setViewBox(0, 0, initViewBoxHeight, initViewBoxWidth, true);
    map.canvas.setAttribute('preserveAspectRatio', 'xMinYMin');
    map.image(Saddledome, 0, 0, initViewBoxWidth, initViewBoxHeight);

    const zones = getZones();
    const seatItems = zones.map(zone => {
      return map
        .path(zone.path)
        .attr(zone.attr)
        .transform(zone.transform)
        .data('zone', zone.data.zone)
        .data('zoneNo', zone.data.zoneNo)
        .data('zoneId', zone.data.zoneId);
    });

    this.seatItems = seatItems;

    return map;
  }

  resetItems() {
    const { availableSeatItems } = this.state;

    availableSeatItems.forEach(seat => {
      seat.node.style.opacity = 0;
      seat.node.style['stroke-width'] = 0;
      seat.node.style.fill = '#2196f3';
      seat.node.setAttribute('cursor', 'default');

      seat.unmouseover();
      seat.unmouseout();
      seat.unclick();
    });
  }

  renderAvailableSeats(zonesList) {
    const seatItems = this.seatItems;
    this.resetItems();

    const seatZones = zonesList;

    const availableSeatItems = seatItems.filter(seat => {
      const section = `${seat.data('zone')} ${seat.data('zoneNo')}`;
      return _.includes(seatZones, section.trim());
    });

    availableSeatItems.forEach(seat => {
      seat.node.setAttribute('cursor', 'pointer');
      seat.node.style.opacity = 0.24;

      seat.mouseover(e => {
        seat.node.style.opacity = 0.5;
      });

      seat.mouseout(e => {
        seat.node.style.opacity = 0.24;
      });

      seat.click(e => {
        seat.node.style.fill = '#e03a3e';
        seat.node.style.opacity = 0.5;
        seat.node.style['stroke-width'] = 2;
        this.onSeatClick(seat);
      });
    });

    this.setState({
      availableSeatItems
    });
  }

  isSeatSelected(seat) {
    const { selectedSeats } = this.state;
    const findSeat = selectedSeats.find(selectedSeat => _.isEqual(selectedSeat.data(), seat.data()));
    return !_.isNil(findSeat);
  }

  addSelection(seat) {
    const { selectedSeats } = this.state;
    this.setState({ selectedSeats: [...selectedSeats, seat] });
  }

  removeSelection(seat) {
    const { selectedSeats } = this.state;
    const newSelectedSeats = selectedSeats.filter(selectedSeat => !_.isEqual(selectedSeat.data(), seat.data()))
    this.setState({ selectedSeats: newSelectedSeats });
    seat.node.style.fill = "#2196f3";
    seat.node.style.opacity = 0.24;
    seat.node.style["stroke-width"] = 0;
  }

  clearSelection() {
    const { selectedSeats } = this.state;
    selectedSeats.forEach(selectedSeat => {
      selectedSeat.node.style.fill = "#2196f3";
      selectedSeat.node.style.opacity = 0.24;
      selectedSeat.node.style["stroke-width"] = 0;
    });
    this.setState({ selectedSeats: [] });
    this.props.onSeatmapItemClick([]);
  }

  onSeatClick(seat) {
    if (this.isSeatSelected(seat)) {
      this.removeSelection(seat);
    } else {
      this.addSelection(seat);
    }

    const selectedZones = this.state.selectedSeats.map(seat => seat.data());
    this.props.onSeatmapItemClick(selectedZones);
  }

  componentDidMount() {
    map = this.createMap();

    const { seats, zonesList } = this.props;

    this.setState({
      seats,
      zonesList
    });

    if (zonesList) {
      this.renderAvailableSeats(zonesList);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.seats !== this.props.seats) {
      this.setState({
        seats: nextProps.seats
      });
    }

    if (nextProps.zonesList !== this.props.zonesList) {
      const { zonesList } = nextProps;
      this.renderAvailableSeats(zonesList);

      this.setState({
        zonesList,
        selectedSeats: []
      });
    }
  }

  renderMap() {
    const { classes } = this.props;

    return (
      <div id="map" className={classes.map} ref={el => (this.mapDiv = el)} />
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedSeats } = this.state;
    return (
      <React.Fragment>
        {selectedSeats.length > 1 && (
          <ButtonClear className={classes.clearButtonWrapper} onClick={() => this.clearSelection()} />
        )}
        {this.renderMap()}
      </React.Fragment>
    );
  }
}

RoughnecksSeatMap.propTypes = {
  seats: PropTypes.array
};

RoughnecksSeatMap.defaultProps = {
  seats: []
};

export default withStyles(styles)(RoughnecksSeatMap);
