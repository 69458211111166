const generateAlphabetRows = (alphabet) => {
  const arr = [];

  alphabet.map((item) => {
    arr.push({
      value: item,
      label: `Row ${item}`,
    });
  });

  return arr;
}

const GARow = [
  {
    value: "GA",
    label: 'Row GA',
  },
];

export default {
  GA_ROW: GARow,
  generateAlphabetRows,
};
    