import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import Helmet from "react-helmet";

import { Button, Grid, Typography } from "@material-ui/core";
import Illustration from "../../images/banners/illustration.png";

import { styles as homeStyles } from "./styles/theme";
import GamesList from "../../components/home-games-list";
import WhatCustomerSayV2 from "../../components/what-customers-say-v2";
import WhyFansfirst from "../../components/why-fansfirst";
import Posts from "../../components/blog";
import SearchAutocomplete from "../../components/search-auto-complete";
import { cflTeamCheck, getLocalizedDateTime, getStaticTeID, getTeamId, getTEPlayoffID, includeTeListingsCheck, rateFallback, renderZoneName, ahlTeamCheck, whlTeamCheck, nllTeamCheck } from "../../libs/shared/seat-helpers";
import { getTeamZone } from "../../libs/shared/get-team-zone";
import { grabJaysLowestFromTE, grabLeafsRapsLowerTE, grabLowestCFLFromTE, grabLowestFromTE, grabLowestMLSFromTE, grabLowestAHLFromTE, grabMarliesSpecialLowerTE, grabLowestWHLFromTE, grabLowestNLLFromTE, grabBellevilleSpecialLowerTE } from "../../libs/shared/grid-helper";
import { teams } from "../../components/home-games-list/team-selector";

const styles = (theme) => homeStyles(theme);

class Home extends Component {
  constructor(props) {
    super();
    const storedRate = localStorage.getItem("currencyRate");
    const fxRateInit = storedRate ? JSON.parse(storedRate) : {timestamp: 1670452262, rate: rateFallback};
    this.state = {
      selectedTeam: !localStorage["selectedTeam"]
        ? "calgary-flames"
        : localStorage["selectedTeam"],
      games: {},
      gamesWithTE: {},
      isLoadingGames: true,
      minPrices: {},
      teLowestParams: [],
      currencyRate: fxRateInit,
      teListings: [],
      teIdList: [],
      selectedParent: !localStorage["selectedParent"]
      ? 'NHL'
      : localStorage["selectedParent"],
      teMinPrices: {}
    };
  }

  async componentDidMount() {
    const { selectedTeam } = this.state;
    this.loadGames({ homeTeamSlug: selectedTeam });
    this._getCurrencyExchangeRate();
  }

  _onParentSelection = (selectedParent) => {
    this.setState({...this.state, selectedParent: selectedParent.parentSlug });
  }

  onTeamSelection = async ({ homeTeamSlug }) => {
    this.setState({ ...this.state, selectedTeam: homeTeamSlug, isLoadingGames: true });

    await this.loadGames({ homeTeamSlug });
    localStorage["selectedTeam"] = homeTeamSlug;
    localStorage["selectedParent"] = this.state.selectedParent;
  };

  loadGames = async ({ homeTeamSlug }) => {
    const {teLowestParams, teListings, games} = this.state;
    const currGames = {...games};
    const tempNewLowestParams = [...teLowestParams];
    
    const url = window.location.href;
    let isDevelopmentServer = false;
    if (url.includes('development') || url.includes('localhost')|| url.includes('coupons')){
      isDevelopmentServer = true
    }
    
    const currentTime = Date.now();
    if (!currGames[homeTeamSlug]) {
      var homeGames = await this.games({ homeTeamSlug });
      
      var minPrice = 0;
      //loop through homeGames array, remove testGames, and find minimum price
      let homeGamesMinPrices = homeGames.filter((game) => isDevelopmentServer ? true : !game.testGame)
      tempNewLowestParams[homeTeamSlug] = [];
      homeGamesMinPrices.forEach((game) => {
        if (includeTeListingsCheck(game) && tempNewLowestParams[homeTeamSlug].length < 6) {
          tempNewLowestParams[homeTeamSlug].push({
            id: game.id,
            homeTeamSlug,
            gameDate: game.date,
            timezone: game.timezone,
            isPlayoffs: game.isPlayoffs,
            playoffSequence: game.playoffSequence,
            isSpecial: game.isSpecial,
          });
        }
        const gameMinPrices = Object.values(game.minPrices).filter(
          (price) => price > 0
        );
        const gameMinPrice = Math.min(...gameMinPrices);
        if (isFinite(gameMinPrice) && (minPrice === 0 || gameMinPrice < minPrice)) {
          minPrice = gameMinPrice;
        }
      });
      console.log("🚀 ~ tempNewLowestParams:", tempNewLowestParams)
      if (isDevelopmentServer) {
        var tbdGames = homeGames
          .filter((game) => game.isTbd && !game.isArchived)
          .sort((a, b) => a.playoffSequence - b.playoffSequence);
        var regulargames = homeGames
          .filter(
            (game) =>
              !game.isTbd && !game.isArchived &&
              moment.now() <= moment(game.date).add(1, "hour").valueOf()
          )
          .sort((a, b) => a.date - b.date);
      } else {
        var tbdGames = homeGames
          .filter((game) => game.isTbd && !game.isArchived && !game.testGame)
          .sort((a, b) => a.playoffSequence - b.playoffSequence);
        var regulargames = homeGames
          .filter(
            (game) =>
              !game.isTbd && !game.isArchived && !game.testGame &&
              moment.now() <= moment(game.date).add(1, "hour").valueOf()
          )
          .sort((a, b) => a.date - b.date);
      }
      
      currGames[homeTeamSlug] = [...regulargames, ...tbdGames].slice(0, 5);
      this.setState({...this.state, games: currGames, minPrices: {...this.state.minPrices, [homeTeamSlug]: minPrice}, isLoadingGames: tempNewLowestParams[homeTeamSlug] && tempNewLowestParams[homeTeamSlug].length > 0, teLowestParams: tempNewLowestParams });
      tempNewLowestParams[homeTeamSlug] && tempNewLowestParams[homeTeamSlug].length > 0 && !teListings[homeTeamSlug] && await this._handleTETeamFetch();
    } else {
      this.setState({...this.state, selectedTeam: homeTeamSlug, isLoadingGames: false });
    }
  };

  games = async ({ homeTeamSlug }) => {
    return API.get(
      "v2",
      `marketplace/events/homepage/by/homeTeamSlug/${homeTeamSlug}?includeMinPrices=true`
    );
  };

  seats = async (game) => {
    try {
      const data = await API.get(
        "v2",
        `marketplace/listings/by/eventId/${game}?include_seller_details=false&include_inactive=false`
      );
      return data;
    } catch (e) {
      console.log("something wrong", JSON.stringify(e));
    }
  };

  renderBanner() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.bannerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.bannerImageContainer}
        >
          <img
            src={Illustration}
            className={classes.bannerImage}
            alt="FansFirst Banner"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.bannerHeadlineContainer}
        >
          <Typography
            variant="display3"
            color="primary"
            className={classes.bannerHeadline}
          >
            <span
              style={{
                display: "block",
                fontWeight: "800",
                fontFamily: "Nunito Sans",
              }}
            >
              Buy tickets with no service fees. Ever.
            </span>
          </Typography>
          <br />
          <Typography
            variant="subheading"
            color="textSecondary"
            className={classes.subheading}
          >
            <span style={{ color: "#00DE94", fontWeight: "bold" }}>
              Tens of thousands
            </span>{" "}
            of fans have purchased their tickets through FansFirst, saving{" "}
            <span style={{ color: "#00DE94", fontWeight: "bold" }}>
              millions
            </span>{" "}
            in fees. See below for their reviews!
          </Typography>
          <span className={classes.searchContainer}>
            <SearchAutocomplete />
          </span>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { selectedTeam, selectedParent, games, isLoadingGames, minPrices, gamesWithTE } = this.state;
    const minPrice = selectedTeam in minPrices ? minPrices[selectedTeam] : 0;
    const currentURL = window.location.href;
    return (
      <div>
        <Helmet>
          <title>
            FansFirst - Buy NHL/NBA/MLB/CFL Tickets At the Best Prices
          </title>
          <meta
            name="description"
            content="Get the best prices on NHL, NBA, MLB, and CFL tickets with FansFirst. Secure your seats now and enjoy live sports action. Book your tickets today!"
          />
          <meta name="keywords" content={`fansfirst, games, tickets, buy tickets, no fees, buy cheap NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="FansFirst - Buy NHL/NBA/MLB/CFL Tickets At the Best Prices" />
          <meta property="og:description" content="Get the best prices on NHL, NBA, MLB, and CFL tickets with FansFirst. Secure your seats now and enjoy live sports action. Book your tickets today!" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {this.renderBanner()}
        <GamesList
          selectedTeam={selectedTeam}
          selectedParent={selectedParent}
          onTeamSelection={this.onTeamSelection}
          onParentSelection={this._onParentSelection}
          games={gamesWithTE}
          isLoadingGames={isLoadingGames}
          teIdList={this.state.teIdList[selectedTeam] || []}
          isGamesEqual={Object.keys(games).length > 0 && Object.keys(games).length === Object.keys(gamesWithTE).length}
        />
        <WhatCustomerSayV2 />
        <WhyFansfirst />
        <Posts />
        <div>
          <Typography
            variant="title"
            align="center"
            color="primary"
            style={{
              marginTop: "5rem",
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            <span>100% Guaranteed.</span>
            <br />
            <span>No hidden fees.</span>
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="extendedFab"
              size="large"
              style={{
                backgroundColor: "#00DE94",
                color: "white",
                padding: "0 2rem",
                marginBottom: "1rem",
              }}
              href={`/${selectedTeam}/games`}
            >
              Buy {!selectedTeam ? "" : selectedTeam.replace(/-/g, " ")} Tickets
              Now
            </Button>
            <Typography variant="caption">
              Prices start at{" "}
              {minPrice === 0 ? "-" : `$${parseFloat(minPrice).toFixed(2)}`}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  
  _handleTETeamFetch = async () => {
    const { selectedTeam: teamSlug } = this.state;
    this.setState({...this.state, isLoadingGames: true});
    const teamId = getTeamId(teamSlug);

    if (teamId === "") {
      this.setState({...this.state, isLoadingGames: false});
      return;
    }
    await API.get(
      "v2",
      `marketplace/ticketEvo/events/index/by/performerId/${teamId}`
    )
      .then((data) => {
        const { events } = data;
        this.setState({
          ...this.state,
          teEvents: events,
        });
        this._handleTEGameFetch(events);
      })
      .catch((e) => {
        this.setState({...this.state, isLoadingGames: false});
        console.error(e);
      });
  };
  
  _getCurrencyExchangeRate = async () => {
    
    await API.get(
      "v2",
      `marketplace/ticketEvo/currencyRate`
    )
      .then((data) => {
        this.setState({...this.state, currencyRate: data.currencyRate});
        localStorage.setItem('currencyRate', JSON.stringify(data.currencyRate));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  _formatTEZoneToFF = (teSeat) => {
    const {selectedTeam: teamSlug} = this.state;
    const zoneInvetory = teSeat.tevo_section_name
      .split(" ")
      .slice(0, -1)
      .join(" ");
    const zoneNo = teSeat.tevo_section_name.split(" ").pop();
    return getTeamZone(teamSlug, zoneInvetory.toLowerCase(), zoneNo);
  };

  _handleTEGameFetch = async (events) => {
    const {teLowestParams, selectedTeam, games, currencyRate, minPrices} = this.state;
    const newGames = {...games};
    const currentLowestParams = teLowestParams[selectedTeam] ? teLowestParams[selectedTeam] : [];
    const teIdList = this.state.teIdList;
    teIdList[selectedTeam] = [];
    this.setState({ 
      teMinPrices: {
        ...this.state.teMinPrices,
        [selectedTeam]: []
      }
    });
    currentLowestParams.forEach(async (lowestParams, lowIndex) => {
      const {gameDate: date, timezone, isPlayoffs, playoffSequence} = lowestParams;
      const gameDate = getLocalizedDateTime(date, timezone).stringDate;
      
      const findGameXTE = events.find((teGame) => {
        const teGameDate = teGame.occurs_at.split("T")[0];
        return gameDate === teGameDate;
      });

      const isStaticIdExist = getStaticTeID({date, timezone, homeTeamSlug: selectedTeam});
      if (!findGameXTE && !isPlayoffs && isStaticIdExist === '') {
        this.setState({ ...this.state, isLoadingGames: false });
        return;
      }

      if (findGameXTE && findGameXTE.id && !isPlayoffs){
        const idItem = {};
        idItem[gameDate] = findGameXTE.id;
        teIdList[selectedTeam].push(idItem);
      }
      if (isStaticIdExist !== '') {
        const idItem = {};
        idItem[gameDate] = isStaticIdExist;
        teIdList[selectedTeam].push(idItem);
      }
      if (isPlayoffs){
        const idItem = {};
        idItem[playoffSequence] = getTEPlayoffID(selectedTeam, playoffSequence);
        teIdList[selectedTeam].push(idItem);
      }
      let teIdToFetch = '';
      if (findGameXTE || isPlayoffs) {
        teIdToFetch = isPlayoffs ? getTEPlayoffID(selectedTeam, playoffSequence) : findGameXTE.id;
      }
      
      const finalTEIdToFetch =  isStaticIdExist === '' ? teIdToFetch : isStaticIdExist;
      const teListingObject = await API.get(
        "v2",
        `marketplace/ticketEvo/listings/index/by/eventId/${finalTEIdToFetch}?order_by=retail_price+ASC`
      ).catch((e) => {
        this.setState({...this.state, isLoadingGames: false});
        console.error(e);
      });

      // get FF listing lowest price zone
      const game = games[selectedTeam].find(game => game.id === lowestParams.id);
      const minPriceZone = [];
      let specialGame = false;
      let playoffGame = false;
      if (game) {
        specialGame = game.isSpecial;
        playoffGame = game.isPlayoffs;
        Object.keys(game.minPricesZone).forEach(key => {
          if (game.minPricesZone[key]) {
            const zone = renderZoneName(game.minPricesZone[key].zone, game.minPricesZone[key].zoneNo, game.minPricesZone[key].row);
            minPriceZone.push(zone.trim().split(" ").pop());
          }
        });
      }
      const sellerSeats = [];
      const sellerListing = await this.seats(lowestParams.id);
      sellerListing.forEach(listing => {
        sellerSeats.push(listing.zoneNo);
      });
      const uniqueSellerSeats = sellerSeats.filter((value, index, array) => array.indexOf(value) === index);
      const currentGame = {date: lowestParams.gameDate, homeTeamSlug: selectedTeam, timezone: lowestParams.timezone, isSpecial: specialGame, isPlayoffs: playoffGame};
      const ticket_groups = teListingObject && teListingObject.ticket_groups ? teListingObject.ticket_groups : [];
      // exclude listing which is listed by ff seller.
      const filtered_ticket_groups = ticket_groups.filter(ticket => !minPriceZone.includes(ticket.section));
      const cflTeamList = cflTeamCheck(selectedTeam)
        ? grabLowestCFLFromTE(
            filtered_ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          )
        : grabLowestFromTE(
            filtered_ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame,
            uniqueSellerSeats
          );
      let leafRaps =
        selectedTeam === "toronto-maple-leafs" || selectedTeam === "toronto-raptors"
          ? grabLeafsRapsLowerTE(
              filtered_ticket_groups,
              lowestParams,
              currencyRate,
              selectedTeam,
              currentGame
            )
          : cflTeamList;
      let mlsTeamList =
        selectedTeam === "vancouver-whitecaps-fc" || selectedTeam === "toronto-fc"
          ? grabLowestMLSFromTE(
              filtered_ticket_groups,
              lowestParams,
              currencyRate,
              selectedTeam,
              currentGame
            )
          : leafRaps;
      let ahlTeamList = (ahlTeamCheck(selectedTeam) && (!specialGame))
          ? grabLowestAHLFromTE(
            filtered_ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          ): mlsTeamList;
      let marliesSpecialList =
        ((selectedTeam === "toronto-marlies") && (specialGame))
          ? grabMarliesSpecialLowerTE(
              filtered_ticket_groups,
              lowestParams,
              currencyRate,
              'toronto-maple-leafs',
              currentGame
            )
          : ahlTeamList;
      let bellevilleSpecialList =
        ((selectedTeam === "belleville-senators") && (specialGame))
          ? grabBellevilleSpecialLowerTE(
              filtered_ticket_groups,
              lowestParams,
              currencyRate,
              'ottawa-senators',
              currentGame
            )
          : marliesSpecialList;
      let whlTeamList = whlTeamCheck(selectedTeam)
          ? grabLowestWHLFromTE(
            filtered_ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          ): bellevilleSpecialList;
      let nllTeamList = nllTeamCheck(selectedTeam)
          ? grabLowestNLLFromTE(
            filtered_ticket_groups,
            lowestParams,
            currencyRate,
            selectedTeam,
            currentGame
          ): whlTeamList;

      let seatList = 
        selectedTeam === "toronto-blue-jays"
          ? grabJaysLowestFromTE(
              filtered_ticket_groups,
              lowestParams,
              currencyRate,
              selectedTeam,
              currentGame
            )
          : nllTeamList;
      
      const upPrice = seatList.find(seat => "up" in seat);
      const midPrice = seatList.find(seat => "mid" in seat);
      const lowPrice = seatList.find(seat => "low" in seat);
      const currentTEMinPrice = {
        gameId: lowestParams.id,
        pressLevelMinPrice: upPrice ? upPrice["up"].price : null
      };
      if (selectedTeam === "toronto-maple-leafs" || selectedTeam === "toronto-raptors") {
        currentTEMinPrice["secondLevelMinPrice"] = lowPrice ? lowPrice["low"].price : null;
        currentTEMinPrice["lowerBowlMinPrice"] = null;
      } else if (selectedTeam === "calgary-stampeders") {
        currentTEMinPrice["secondLevelMinPrice"] = null;
        currentTEMinPrice["lowerBowlMinPrice"] = seatList.length > 0 ? seatList[0].price : null;
      } else {
        currentTEMinPrice["secondLevelMinPrice"] = midPrice ? midPrice["mid"].price : null;
        currentTEMinPrice["lowerBowlMinPrice"] = lowPrice ? lowPrice["low"].price : null;            
      }
      this.setState({ 
        teMinPrices: {
          ...this.state.teMinPrices,
          [selectedTeam]: [
            ...this.state.teMinPrices[selectedTeam],
            currentTEMinPrice
          ]
        }
      });

      if (seatList.length > 0) {
        seatList.forEach((seat, index) => {
          if (newGames[selectedTeam] && teLowestParams[selectedTeam]) {
            newGames[selectedTeam].forEach((game, key) => {
              if (game.id === teLowestParams[selectedTeam][lowIndex].id) {
                if (
                  selectedTeam !== "ottawa-senators" &&
                  selectedTeam !== "calgary-flames" &&
                  selectedTeam !== "toronto-raptors" &&
                  selectedTeam !== "winnipeg-jets" &&
                  selectedTeam !== "toronto-blue-jays" &&
                  selectedTeam !== "toronto-maple-leafs" &&
                  selectedTeam !== "manitoba-moose" &&
                  selectedTeam !== "vancouver-canucks" &&
                  !cflTeamCheck(selectedTeam)
                ) {
                  // this._storeLowestPrice(game.id, seat.price);
                  if (
                    game.minPrices.pressLevelMinPrice > seat.price ||
                    !game.minPrices.pressLevelMinPrice
                  ) {
                    newGames[selectedTeam][key]["minPrices"][
                      "pressLevelMinPrice"
                    ] = Number(seat.price);
                    this.setState({ ...this.state, games: newGames });
                  }
                }
                if (selectedTeam === "calgary-flames") {
                  const lowData = seat['low'];
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "calgary-stampeders") {
                  // this._storeLowestPrice(game.id, seat.price);
                  if (game.minPrices.lowerBowlMinPrice > seat.price || !game.minPrices.lowerBowlMinPrice) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(seat.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "edmonton-elks") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "vancouver-canucks") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "winnipeg-jets") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "manitoba-moose") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "abbotsford-canucks") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if ((selectedTeam === 'toronto-marlies') && (!game.isSpecial)) {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if ((selectedTeam === 'toronto-marlies') && (game.isSpecial)) {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "belleville-senators") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                
                if (selectedTeam === "winnipeg-blue-bombers") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "toronto-argonauts") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "toronto-fc") {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'toronto-blue-jays') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "ottawa-senators") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'bc-lions') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'vancouver-whitecaps-fc') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'hamilton-tigercats') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'grey-cup') {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'saskatchewan-roughriders') {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'ottawa-redblacks') {
                  const lowData = seat['low'];
                  // const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  // if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                  //   newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                  //   this.setState({...this.state, games: newGames});
                  // }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'toronto-maple-leafs') {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === 'toronto-raptors') {
                  const lowData = seat['low'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.secondLevelMinPrice > lowData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "calgary-hitmen") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
                if (selectedTeam === "calgary-roughnecks") {
                  const lowData = seat['low'];
                  const midData = seat['mid'];
                  const upData = seat['up'];
                  if (upData && (game.minPrices.pressLevelMinPrice > upData.price || !game.minPrices.pressLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["pressLevelMinPrice"] = Number(upData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (midData && (game.minPrices.secondLevelMinPrice > midData.price || !game.minPrices.secondLevelMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["secondLevelMinPrice"] = Number(midData.price);
                    this.setState({...this.state, games: newGames});
                  }
                  if (lowData && (game.minPrices.lowerBowlMinPrice > lowData.price || !game.minPrices.lowerBowlMinPrice)) {
                    newGames[selectedTeam][key]["minPrices"]["lowerBowlMinPrice"] = Number(lowData.price);
                    this.setState({...this.state, games: newGames});
                  }
                }
              }
            });
          }

          var minPrice = selectedTeam in minPrices ? minPrices[selectedTeam] : 0;
          newGames[selectedTeam] && newGames[selectedTeam].forEach(game => {
            const gameMinPrices = Object.values(game.minPrices).filter(
              (price) => price > 0
            );
            const gameMinPrice = Math.min(...gameMinPrices);
            if (isFinite(gameMinPrice) && (minPrice === 0 || gameMinPrice < minPrice)) {
              minPrice = Number(gameMinPrice).toFixed(0);
            }
          });
          this.setState({ minPrices : { ...minPrices, [selectedTeam]: minPrice }});
        });
      }
    });
    this.setState({
      ...this.state,
      teIdList: teIdList,
      isLoadingGames: false,
    });
  };

  _getStoredLowest = async (gameId, games) => {
    const { selectedTeam} = this.state;
    const newGames = games;
    await API.get(
      "v2",
      `marketplace/ticketEvo/getLowestPrice?gameId=${gameId}`
    ).then((data) => {
    if (newGames[selectedTeam]) {
      const currentIndex = newGames[selectedTeam].findIndex((game) => game.id === gameId);
      const lowestData = data.length > 0 && data[0];
      const isStoredPriceLower = currentIndex > -1 && newGames[selectedTeam][currentIndex].minPrices.pressLevelMinPrice > lowestData.press_level_min_price && lowestData;
      const isNewGameExist = currentIndex > -1 && newGames[selectedTeam][currentIndex] && newGames[selectedTeam][currentIndex].minPrices.pressLevelMinPrice
      const isUseStored = isStoredPriceLower || !isNewGameExist;
      if (
        isUseStored &&
        currentIndex > -1 &&
        selectedTeam !== "toronto-blue-jays" &&
        selectedTeam !== "toronto-maple-leafs" &&
        !cflTeamCheck(selectedTeam)
      ) {
        newGames[selectedTeam][currentIndex]["minPrices"][
          "pressLevelMinPrice"
        ] = Number(lowestData.press_level_min_price);
        this.setState({ ...this.state, games: newGames });
      }
    }
    });
    
  }

  _storeLowestPrice = async (teMinPrices) => {
    const data = await API.post("v2", "marketplace/ticketEvo/saveLowestPrice", {
      body: { teMinPrices },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {gamesWithTE, selectedTeam, games, selectedParent, teMinPrices, teIdList} = this.state;

    if (teMinPrices[selectedTeam] && prevState.teMinPrices[selectedTeam]) {
      if (teMinPrices[selectedTeam].length > prevState.teMinPrices[selectedTeam].length) {
        if (teMinPrices[selectedTeam].length === teIdList[selectedTeam].length) {
          this._storeLowestPrice(teMinPrices[selectedTeam]);
        }
      }
    }

    // if (JSON.stringify(prevState.teLowestParams[selectedTeam]) !== JSON.stringify(teLowestParams[selectedTeam]) && ((selectedTeam !== 'toronto-blue-jays') && !cflTeamCheck(selectedTeam))) {
    //   teLowestParams[selectedTeam] && teLowestParams[selectedTeam].forEach((lowest) => this._getStoredLowest(lowest.id, games));
    // }
    if (Object.keys(games).length < Object.keys(prevState.games).length && selectedTeam) {
      this.loadGames({homeTeamSlug: selectedTeam});
    }
    if (Object.keys(games).length > Object.keys(prevState.games).length && selectedTeam) {
      this.setState({...this.state, gamesWithTE: games, isLoadingGames: false});
    }
    if (Object.keys(gamesWithTE).length > Object.keys(prevState.gamesWithTE).length && selectedTeam) {
      this.setState({...this.state, isLoadingGames: false});
    }
    if (selectedParent !== prevState.selectedParent) {
      const matchedTeam = teams.find((team) => team.parentSlug === selectedParent);
      const filteredTeams = matchedTeam ? matchedTeam.teams : [];
      if (filteredTeams.length === 1) {
        this.onTeamSelection({homeTeamSlug: filteredTeams[0].homeTeamSlug})
      }
    }
  }
}

export default withStyles(styles)(Home);
